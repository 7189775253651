import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// API
import API from "../../utils/API";

// ASSETS
import illustration from '../../assets/dashboard-illustration.svg';
import SearchBar from '../../components/Fields/SearchBar';
import PaginationBar from '../../components/Fields/PaginationBar';
import AdminTable from '../../components/Fields/AdminTable';

// COMPONENTS
import { Grid, Typography, Button, IconButton, Toolbar, Divider } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import ProjectCard from '../components/Cards/ProjectCard';
import Pagination from 'material-ui-flat-pagination';
import Paper from '@material-ui/core/Paper';
// import Filter from '../components/Filter';
// import CreateProjectModal from '../components/Modals/CreateProjectModal';

import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';

import SyncomsTable from '../../components/SyncomsTable';

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing.unit * 5.5,
    paddingBottom: theme.spacing.unit * 8.5
  },
  wrapper: {
    textAlign: 'center',
  },
  subNavWrapper: {
    marginBottom: theme.spacing.unit * 3,
  },
  subNavLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 1,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  subNavButton: {
    marginLeft: 'auto',
  },
  illustration: {
    display: 'block',
    margin: '0 auto 12px',
  },
  subTitle: {
    fontWeight: 300,
    marginBottom: 30,
  },
  newProjectButton: {
    borderRadius: 24,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  projectCard: {
    marginBottom: theme.spacing.unit * 4.25,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  pagination: {
    marginTop: theme.spacing.unit * 5
  },
  paper: {
    position: 'relative'
  },
  toolBar: {
    minHeight: 'auto',
    backgroundColor: '#2a0714',
    boxShadow: 'none',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingBottom: 30,
    }
  },
  topContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 35
    }
  },
  bottomContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  filter: {
    marginLeft: theme.spacing.unit * 3,
  },
  filterLabel: {
    marginRight: theme.spacing.unit * 5,
    color: theme.palette.common.white,
  },
  dropdownIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1,
    display: 'none'
  },
  buttonIcon: {
    marginLeft: 15,
  },
});

let baseLink = "/plan-modifications/",
    tableKeys = [
      {'label':'Original', 'id': 'original', 'numeric': false, 'disablePadding': true, },
      {'label':'Plan Status', 'id': 'plan_status', 'numeric': false, 'disablePadding': true, },      
      {'label':'Approved', 'id': 'approved', 'numeric': false, 'disablePadding': true, },      
      {'label':'Approved By', 'id': 'approved_by', 'numeric': false, 'disablePadding': true, }, 
    ],
    labels = {
      'singular': "Plan Modification",
      "plural": "Plan Modifications",
      "placeholder": "Search Plan Modifications"
    };


class PlanModificationList extends SyncomsTable {
  constructor(props) {
    super(props);
    this.state.baseLink = baseLink;
    this.state.labels = labels;
    this.state.tableKeys = tableKeys;
    this.state.canAdd = false;
  }

  getColourFromStatus = (plan_status) => {
    if (plan_status == "Draft") {
      return "disabled";
    }
    if (plan_status == "In Review") {
      return "secondary";
    }
    return "primary";
  }


  updateItem(item) {
    item.approved = {'label': item.approved, 'type': 'boolean'};
    item.plan_status = {'label': item.plan_status, 'type': 'button',  'colour': this.getColourFromStatus(item.plan_status)};
    item.original = {'label': item.name, 'type': 'link', id: item.id, path: baseLink};
    return item;
  }
}

PlanModificationList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(PlanModificationList));