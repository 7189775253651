import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Select, OutlinedInput } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '../../assets/icon-delete.png';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InfoIcon from '@material-ui/icons/Info';
import AppModal from "../../components/Modals/AppModal"

import EditToolbar from '../../components/Fields/EditToolbar';
import AdminTable from '../../components/Fields/AdminTable';
import AutoComplete from '../../components/Fields/AutoComplete';

// API
import API from "../../utils/API";

const styles = theme => ({
  base: {},
  root: {},
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    position: 'relative',
    paddingBottom: 40
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  overflowAllowed: {
    overflow: 'visible'
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  infoIcon: {
    marginLeft: 4,
    width: 15,
    height: 14,
    color: theme.palette.primary.light,
    verticalAlign: 'inherit'
  },
  tableBody: {
    '& tr td': {
      padding: '25px 5px'
    }
  },
  deleteIcon: {
    height: 24,
    width: 21
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  formRow: {
    marginBottom: theme.spacing.unit
  },
  summarySelect: {
    width: '100%',
    height: 40,
  },
  dropdownIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1,
    display: 'none'
  },
  cardButton: {
    color:theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    borderRadius: 24,
    fontSize: 15,
    marginTop: 20,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    }
  },
  addIcon: {
    color:theme.palette.primary.light,
    marginLeft: 15,
  },
  checkboxInline: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    marginRight: theme.spacing.unit*3,
  },
  tableSelect: {
    display: 'flex',
    width: '90%',
    marginTop: theme.spacing.unit*2,
    height: 40,
  },
  topAlign: {
    verticalAlign:'top'
  },
  generateSummaryContainer: {
    [theme.breakpoints.up('md')]: {
      display: "flex",
      justifyContent: 'flex-end'
    },
  }
});

let baseLink = "/small-summaries/";
class SmallSummaryAdd extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {
      value: 'true',
      item: {'sbc_policy_period_month': '', 'sbc_policy_period_year': ''},
      inlineItems: [{'generate_sbc': false}],
      checkedItems: [],
      options: {
        'actions': ['save', 'delete'],
      },
      modalOpen: false,
      modalMessage: '',
      isSaving: false,
      months: [
          [1, "January"],
          [2, "February"],
          [3, "March"],
          [4, "April"],
          [5, "May"],
          [6, "June"],
          [7, "July"],
          [8, "August"],
          [9, "September"],
          [10, "October"],
          [11, "November"],
          [12, "December"],
      ],
      years: [
          ["2016", "2016"],
          ["2017", "2017"],
          ["2018", "2018"],
          ["2019", "2019"],
          ["2020", "2020"],
          ["2021", "2021"],
      ]
    };
  }

  getUrl = () => {
    let url = baseLink;
    if (this.props.match.params.id) {
      url += this.props.match.params.id;
    }
    return url;
  }

  handleChange = event => {
    let item = this.state.item;
    item[event.target.name] = event.target.value
    this.setState({ item });
  };

  // handling inline items
  handleAddInline = () => {
    let { inlineItems } = this.state;
    inlineItems.push({'generate_sbc': false});
    this.setState({ inlineItems });
  }

  handleDeleteInline = (idx) => {
    let { inlineItems } = this.state;
    inlineItems.splice(idx, 1);
    this.setState({ inlineItems });
  }

  handleOnCheckboxChange = (option, e) => {
    let { checkedItems } = this.state;
    if (checkedItems.indexOf(option) >= 0) {
      checkedItems.splice(checkedItems.indexOf(option), 1);
    } else {
      checkedItems.push(option);
    }
    this.setState({checkedItems});
  }

  handleOnCheckboxUpdate = (idx, option, e, value) => {
    let { inlineItems } = this.state,
        inlineItem = inlineItems[idx],
        isChecked = e.target.checked;
    if (isChecked) {
      inlineItem[option] = true;
    } else {
      inlineItem[option] = false;
    }
    this.setState({inlineItems});
  }

  // for inlines
  handleUpdate = (idx, name, value) => {
    let { inlineItems } = this.state,
        inlineItem = inlineItems[idx];
    inlineItem[name] = value;
    this.setState({inlineItems});
  }

  handleAction = (action) => {
    if (action == 'save') {
      this.handleSave();
      return;
    }
    if (action == 'delete') {
      this.handleDelete();
      return;
    }
  }

  handleSave = (e) => {
    if (this.state.isSaving) {
      return false;
    }
    let item = this.state.item,
        url = this.getUrl(true),
        inlineItems = this.state.inlineItems;
    if (!this.isValidItem(item, inlineItems)) {
      this.setState(state => ({
        modalOpen: true,
        modalMessage: "You must complete the form before continuing"
      }))
      return;
    }
    let dict = {
      'summary': item,
      'files': inlineItems
    }

    this.setState({isSaving:true})

    API.post(url, dict)
      .then((res) => {
        if (res.data.success) {
          this.props.history.push(baseLink);
          } else {
            this.setState(state => ({
              modalOpen: true,
              modalMessage: "Cannot save with the current details",
              isSaving: false
            }))
        }

      })
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: "Cannot save with the current details",
          isSaving: false
        }))
      });
  }

  isValidItem = (item, inlineItems) => {
    if (!inlineItems.length) {
      return false;
    }
    for (let inlineItem of inlineItems) {
      if (!inlineItem.small_group_policy) {
        return false;
      }
      if (inlineItem.generate_sbc){
        if (!(item.sbc_policy_period_month && item.sbc_policy_period_year)) {
          return false;
        }
      }
    }
    return true;
  }

  handleModalClose = () => {
    this.setState(state => ({
      modalOpen: false,
    }))
  }

  render() {
    const { classes } = this.props;
    const { item, inlineItems, options, smallPlan, effectiveDate, sbcPeriodPolicyMonth, sbcPeriodPolicyYear} = this.state;

    return (
      <div className={classes.base}>
        <AppModal open={this.state.modalOpen} textMessage={this.state.modalMessage} handleClose={this.handleModalClose}/>
        <Grid container component="main" alignItems={'center'} className={classes.root}>
          <Grid item xs={9}>
            <Typography component="h2" variant="h1" className="pageTitle">
              Summaries
              <KeyboardArrowUpIcon className={classes.dropdownIcon}/>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className={classes.form} noValidate>
              <Paper className={classes.paper}>
                <Grid container className={classes.formcontainer}>
                  <EditToolbar options={options} handleAction={(action)=> this.handleAction(action)} />
                  <Divider className='searchLine searchLine__toolbar'/>
                </Grid>
                <Grid container className={classes.formcontainer}>
                  <Grid item xs={12} sm={6}>
                    <Typography component="h3" className={classes.paperTitle}>Add Summary</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={6}>
                    <Grid container spacing={16} className={classes.formRow}>
                      <Grid item xs={12} sm={6}>
                        <Typography className="formLabel">Group Name</Typography>
                        <TextField
                          required
                          fullWidth
                          id="group_name"
                          name="group_name"
                          value={item.group_name}
                          onChange={(e)=>this.handleChange(e)}
                          />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography className="formLabel">Effective Date</Typography>
                        <Select
                          name="effective_date"
                          value={item.effective_date}
                          onChange={(e) => this.handleChange(e)}
                          className={classes.summarySelect}
                        >
                          {this.state.months.map((obj, idx) => {
                              let value = obj[0],
                                  label = obj[1];
                              return (
                                <MenuItem value={value}>{label}</MenuItem>
                              )
                            })}
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid container spacing={16} className={classes.formRow}>
                      <Grid item xs={12} sm={6}>
                        <Typography className="formLabel">SBC Period Policy Month</Typography>
                        <Select
                          name="sbc_policy_period_month"
                          value={item.sbc_policy_period_month}
                          onChange={(e) => this.handleChange(e)}
                          className={classes.summarySelect}
                        >
                          {this.state.months.map((obj, idx) => {
                              let value = obj[0],
                                  label = obj[1];
                              return (
                                <MenuItem value={value}>{label}</MenuItem>
                              )
                            })}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography className="formLabel">SBC Period Policy Year</Typography>
                        <Select
                          name="sbc_policy_period_year"
                          value={item.sbc_policy_period_year}
                          onChange={(e) => this.handleChange(e)}
                          className={classes.summarySelect}
                        >
                          {this.state.years.map((obj, idx) => {
                              let value = obj[0],
                                  label = obj[1];
                              return (
                                <MenuItem value={value}>{label}</MenuItem>
                              )
                            })}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>           
                <Divider className="searchLine__table"/>
                <Grid container style={{marginTop: 30}}>
                   <Grid item xs={12} sm={6}>
                    <Typography component="h3" style={{marginBottom: 8}} className={classes.paperTitle}>Summary Lines</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                      <TableHead>
                        <TableRow style={{height: 50}}>
                          <TableCell>
                            Small Group Policy
                          </TableCell>                          
                          <TableCell style={{textAlign: 'center'}}>
                            Generate SBC
                          </TableCell>          
                          <TableCell style={{textAlign: 'center'}}>
                            Delete
                          </TableCell>
                        </TableRow>
                      </TableHead>  
                      <TableBody className={classes.tableBody}>
                        {inlineItems.length > 0 &&
                          <React.Fragment>
                            {inlineItems.map((inlineItem, idx) => {
                              let checkedItems = this.state.checkedItems,
                                  documentTypes = [],
                                  rxDocumentTypes = [],
                                  planYears = [],
                                  rxPlanYears = [];
                                  if (checkedItems.indexOf('hmo' + idx)>=0) { documentTypes.push('HMO'); }
                                  if (checkedItems.indexOf('pos' + idx)>=0) { documentTypes.push('POS'); }
                                  if (checkedItems.indexOf('hmorx' + idx)>=0) { rxDocumentTypes.push('HMO Rx'); }
                                  if (checkedItems.indexOf('posrx' + idx)>=0) { rxDocumentTypes.push('POS Rx'); }
                                  if (checkedItems.indexOf('2019' + idx)) { planYears.push('2019'); }
                                  if (checkedItems.indexOf('2019rx' + idx)) { rxPlanYears.push('2019'); }
                                return (
                                  <TableRow className={classes.topAlign}>
                                    <TableCell className={classes.overflowAllowed}>
                                    <div className={classes.checkboxInline}>
                                        <Checkbox checked={checkedItems.indexOf('hmo' + idx) >= 0} onChange={(e) => this.handleOnCheckboxChange('hmo' + idx, e)} /> <div className={classes.checkboxLabel}>HMO</div>  
                                      </div>                       
                                      <div className={classes.checkboxInline}>
                                        <Checkbox checked={checkedItems.indexOf('pos' + idx) >= 0} onChange={(e) => this.handleOnCheckboxChange('pos' + idx, e)} /> <div className={classes.checkboxLabel}>POS</div>   
                                      </div>                  
                                      <div className={classes.checkboxInline}>
                                        <Checkbox checked={checkedItems.indexOf('2019' + idx) >= 0} onChange={(e) => this.handleOnCheckboxChange('2019' + idx, e)} /> <div className={classes.checkboxLabel}>2019</div> 
                                      </div>
                                      <AutoComplete type="small-plans" DocumentTypes={documentTypes} PlanYears={planYears} update={(value) => this.handleUpdate(idx, 'small_group_policy', value) } />
                                    </TableCell>
                                    <TableCell style={{textAlign: 'center'}}>
                                      <Checkbox checked={inlineItem.generate_sbc} onChange={(e) => this.handleOnCheckboxUpdate(idx, 'generate_sbc', e)} />
                                    </TableCell>                          
                                    <TableCell style={{textAlign: 'center'}}>
                                      <IconButton onClick={(e)=>this.handleDeleteInline(idx)}>
                                        <img src={DeleteIcon} className={classes.deleteIcon}/>
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )}
                              )}
                          </React.Fragment>
                        }
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
                <Grid container alignContent="space-between">
                  <Grid item xs={12} md={6}>
                      <IconButton className={classes.cardButton} variant="contained" onClick={this.handleAddInline}>Add another Summary line <AddCircleIcon className={classes.addIcon}/></IconButton>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.generateSummaryContainer}>
                      <IconButton className={classes.cardButton} variant="contained" onClick={() => this.handleSave()}>Save<AddCircleIcon className={classes.addIcon}/></IconButton>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SmallSummaryAdd.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SmallSummaryAdd);