import React, {useEffect} from 'react';

function Logout(props) {
console.log(props.history)
  useEffect(() => {
    localStorage.setItem("token", '');
    props.history.push('/login')
  }, [])
      return (
      <>
      <p></p>
      </>
    )
}

export default Logout
