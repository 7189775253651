import React from 'react';

// COMPONENTS
import { Grid, Typography } from '@material-ui/core';

export default function Footer() {
  return (
    <footer style={{ display: 'block', padding: '20px 0px' }}>
      <div className={'container'}>
        <Grid container justify={'center'}>
          <Grid item>
            <Typography variant="caption" style={{ fontSize: 10 }}>
              The Connecticare system is copyright and registered trade marks (&copy; and &reg;) of Connecticare Ltd. All rights reserved.        
            </Typography>
          </Grid>
        </Grid>
      </div>
    </footer>
  )
}