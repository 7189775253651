import React from 'react';
import { BrowserRouter, Link, Route, Switch, } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { LockOpen } from '@material-ui/icons';


import Logo from '../../assets/connecticare-logo.png';
import LoginBackground from '../../assets/login-illustration.png';
import AlertDialog from '../alerts/Alert';
import API from '../../utils/API';

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${LoginBackground})`,
    backgroundColor: '#ebf4fb',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'center',
    backgroundPosition: 'center',
  },
  papercontainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width:'30%',
    marginBottom: theme.spacing.unit * 4,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 48,
    fontWeight: '700',
    marginBottom: theme.spacing.unit * 8
  },
  subTitle: {
    fontSize:30,
    fontWeight: '700',
    marginBottom: theme.spacing.unit * 4
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  formcontainer: {
    paddingBottom:'2rem',
    borderBottom: '1px solid #6d6f72',
    marginBottom: '2rem'
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: '12px',
    color: '#6d6f72'
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0),
    textTransform: 'initial',
    borderRadius: '5px',
    minWidth: '100px'
  },
  forgotten: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '500'
  },
  formRow: {
    marginBottom: theme.spacing.unit*3
  },
  loginText: {
    backgroundColor: 'transparent',
  }
});

class ResetPassword extends React.Component {
  constructor(props) {
      super(props);
    this.state = {
      message: null,
      open: false,
      password: null,
      confirmPassword: null,
      verificationToken: props.match.params.id,
      canContinue: false
    }
    this.confirmValidVerificationToken(this.state.verificationToken);
  }

  confirmValidVerificationToken = (verificationToken) => {
    API.get('/verification-token/' + verificationToken)
    .then((res) => {
      if (res.data.success) {
        this.setState({canContinue: true});
        return;
      }
      this.setState({
          'message': 'Cannot confirm valid verification token',
          'open': true
        });
    })
    .catch((error) => {
      this.setState({
          'message': 'Cannot continue with the current details',
          'open': true
        });
    });
  }

  handleClose = () => {
    this.setState({'open': false});
  }

  handleResetPassword = (event) => {
    event.preventDefault();
    let password = this.state.password,
        confirmPassword = this.state.confirmPassword,
        verificationToken = this.state.verificationToken;

    if (!(password && confirmPassword)) {
      this.setState({
          'message': 'You must complete the form correctly',
          'open': true
        });
      return false;
    }
    if (password.length < 8) {
      this.setState({
          'message': 'Password strength is not enough',
          'open': true
        });
      return false;
    }
    if (password != confirmPassword) {
      this.setState({
          'message': 'Passwords do not match',
          'open': true
        });
      return false;
    }
    this.resetPassword(verificationToken, password);
    return false;
  }

  resetPassword = (verificationToken, password) => {
    API.post('/reset-password/', {
      verification_token: verificationToken,
      password: password,
    })
    .then((res) => {
      if (res.data.success) {
        this.setState({
          'message': 'Password successfully reset, you may now login again',
          'open': true});
        return;
        }
        this.setState({
            'message': 'There was an undefined issue in handling this.',
            'open': true
          });

    })
    .catch((error) => {
      this.setState({
          'message': 'Cannot continue with the current details',
          'open': true
        });
    });
    
  }

  handleUpdate = (name, value) => {
    this.setState({[name]: value});
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleResetPassword(e);
    }
  }
  render() {
    const { classes } = this.props;
    const { canContinue, open, message } = this.state;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={6} md={6} className={classes.image} />
        {canContinue && 
          <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square className={classes.papercontainer}>
            <div className={classes.paper}>
              <Grid container justify={'center'}>
                <Grid item xs={6}>
              <img src={Logo} className={classes.logo} alt="logo" />
              <Typography component="h2" variant="h1" className={classes.title}>
                  Connecticare Administration
              </Typography>
              <Typography component="h2" variant="h2" className={classes.subTitle}>
                  Reset password
              </Typography>
              <Typography component="body1">
                  Reset your password below.
              </Typography>
              <form className={classes.form} noValidate>
                <Grid container className={classes.formcontainer}>
                    <Grid item xs={12} sm={12} className="formLabel">Password</Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        name="password"
                        type="password"
                        onKeyDown={this._handleKeyDown}
                        onChange={e => this.handleUpdate('password', e.target.value)}
                      />
                    </Grid> 
                    <Grid item xs={12} sm={12} className="formLabel">Confirm Password</Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        onKeyDown={this._handleKeyDown}
                        onChange={e => this.handleUpdate('confirmPassword', e.target.value)}
                      />
                  </Grid>
                </Grid>  
                <Grid container alignItems="center">
                <Grid item xs>
                  <Link to="/login" variant="body2" className={classes.forgotten}>
                    Login
                  </Link>
                </Grid>
                  <Grid item xs align="right">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={this.handleResetPassword}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
                </Grid>
              </Grid>
            </div>
          </Grid>
        }
        <AlertDialog open={open} message={message} close={this.handleClose} />
      </Grid>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResetPassword);