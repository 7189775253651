import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import IconHistory from '../../assets/icon-history.png'
import CheckCircleOutlineIcon from '../../assets/icon-check-circle.png';
import CheckIcon from '../../assets/icon-check.png';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Select, OutlinedInput, Divider } from '@material-ui/core';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CalendarIcon from '../../assets/icon-calendar.png';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import AutoComplete from '../Fields/AutoComplete';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';

function TabContainer(props) {
  return (
      props.children
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  base: {},
  root: {},
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  styleForRightBorder: {
    borderRight: '1px solid #e7edee'
  },

  styleForLeftBorder: {
    borderLeft: '1px solid #e7edee'
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  textarea: {
    padding: 0,
    maxWidth: 120
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  paper: {
    position: 'relative'
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,   
    paddingBottom: theme.spacing.unit*1,   
    paddingLeft: theme.spacing.unit*2, 
    paddingRight: theme.spacing.unit*2,
    borderRadius: 24,
    fontSize: 18,
    width: 150,
    justifyContent: 'space-between',
    fontSize: 18,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#27cfda'
    }
  },
  previousButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent'
    }
  },
  labelForDatePicker: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Calibri',
    fontSize: 18,
    fontWeight: 40,
    color: theme.palette.primary.main
  },
  benefitTableHead: {
    backgroundColor: theme.palette.background.default,
    '& tr': {
      '& th': {
        textTransform: 'capitalize',
        color: theme.palette.primary.main,
        fontWeight: 600
      }
    }
  },
  buttonIcon: {
    width: 19,
    height: 17
  },
  checkIcon: {
    width: 18,
    height: 'auto'
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  formRow: {
    marginBottom: theme.spacing.unit*3
  },
  summarySelect: {
    width: '100%',
    height: 40,
  },
  inputWrapper: {
      display: 'flex',
      alignItems: 'flex-end'
  },
  inputBlockWrapper: {
    flexDirection: 'column', 
    display: 'flex', 
    justifyContent: 'space-between'
  }
});

let baseLink = "/plans/";

class TabForm extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {
      formFields: props.formFields,
      item: props.item,
      benefits: props.benefits,
      fieldObj: props.fieldObj,
      benefitCellList: ['benefit_lang','benefit_in','benefit_in2','benefit_in3','benefit_in4','benefit_out','benefit_out2'],
      editedText: "",
      edit: false,
      currentCellId: null,
      currentCellTitle: null
    }
  }

  // handle benefit update
  handleCell = (id, title, text) => {
    this.setState({
      editedText: text,
      edit: true,
      currentCellId: id,
      currentCellTitle: title
    })
  }

  modifyTextInCell = (e) => {
    this.setState({
      editedText: e.target.value
    })
  }

  saveChanges = () => {
    let benefits = this.state.benefits;
    for (let key in benefits) {
      if(benefits[key].id === this.state.currentCellId) {
        benefits[key][this.state.currentCellTitle] = this.state.editedText;
      }
    }
    this.setState({
      benefits,
      edit: false,
      currentCellId: null,
      currentCellTitle: null
    })
  }

  handleToggleVisible = (benefitId) => {
    let benefits = this.state.benefits;
    for (let key in benefits) {
      if(benefits[key].id === benefitId) {
        benefits[key].is_visible = !benefits[key].is_visible;
      }
    }
    this.setState({
      benefits,
      edit: false,
      currentCellId: null,
      currentCellTitle: null
    })
  }

  handleChange = (key, e) => {
    let value = e.target.value,
        {item} = this.state;
    item[key] = value;
    this.setState({item});
  }

  handleAutoCompleteChange = (key, value) => {
    let {item} = this.state;
    item[key] = value;
    this.setState({item});
  }

  handleOnCheckboxChange = (key, e) => {
    let checked = e.target.checked,
        {item} = this.state;
    item[key] = checked;
    this.setState({item});
  }

  handleDateChange = (date, name )=> {
    let {item} = this.state;
    item[name] = date;
    this.setState({item});
  };

  

  render() {
    const { classes, users, underwriters, vendors, underwriterCannotEdit } = this.props;
    const { formFields, item, benefits, benefitCellList, edit, editedText, currentCellTitle, currentCellId, fieldObj } = this.state;

    let isBenefit = false,
        formName = null,
        formOptions = null;
    if (formFields.length) {
      formName = formFields[0];
      formOptions = formFields[1].fields;
    }
    return (
      <React.Fragment>
        {formName &&
          <React.Fragment>
          <Grid container>
            <Grid item xs={12} sm={10}>
              <Grid container spacing={40} className={classes.formRow}>
                {formOptions.map(option => {
                  if (typeof(option) == 'object') {
                    let value = item[option[0]],
                        field = fieldObj[option[0]],
                        value2 = item[option[1]];
                    if (!field) {
                      if (option[0].indexOf("adjust") >= 0) {
                        return (<Grid container spacing={40} className={classes.formRow}>
                                                  <Grid item xs={12} sm={3}>
                                                    <Grid item xs={12} sm={12} className={classes.formLabelForRequest}></Grid>
                                                      
                                                  </Grid>  
                                                  <Grid item xs={12} sm={3}>
                                                    <Grid item xs={12} sm={12}>Adjust from</Grid>
                                                    <Grid item xs={12} sm={12}>
                                                          
                                                    </Grid>
                                                  </Grid>               
                                                  <Grid item xs={12} sm={3}>
                                                    <Grid item xs={12} sm={12}>Requested</Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        
                                                    </Grid>
                                                  </Grid>
                                                </Grid>)
                      }
                        return;
                    }
                    return (
                      <Grid container alignItems="center" spacing={40} className={classes.formRow}>
                        <Grid item xs={12} sm={3}>
                          <Grid item xs={12} sm={12}>
                             <Grid item xs={12} sm={12} className={classes.formLabelForRequest}>{field.label}</Grid>
                          </Grid>
                        </Grid>                 
                        <Grid item xs={12} sm={3}>
                          <Grid item xs={12} sm={12}>{value}</Grid>
                          <Grid item xs={12} sm={12}>
                            
                          </Grid>
                        </Grid>               
                        <Grid item xs={12} sm={3}>
                          <Grid item xs={12} sm={12}>
                              <TextField
                                value={value2}
                                fullWidth
                                id={option[1]}
                                name={option[1]}
                                disabled={underwriterCannotEdit}
                                onChange={(e) => this.handleChange(option[1], e)}
                              />
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  }
                  let value = item[option],
                      field = fieldObj[option],
                      fieldToShow = "text";
                      if (field.choices.length) {
                        fieldToShow = "select";
                      }
                      if (field.type =="BooleanField") {
                        fieldToShow = "checkbox";
                      }
                      if (field.type =="DateField") {
                        fieldToShow = "date";
                      }
                      if (field.type =="ForeignKey") {
                        fieldToShow = "autocomplete";
                      }
                    let usersToUse = users;

                    if (option == 'underwriter') {
                      usersToUse = underwriters;
                    } else {
                      usersToUse = vendors;
                    }
                  return (
                    <Grid item xs={12} sm={4} className={classes.inputBlockWrapper}>
                      <Grid item xs={12} sm={12} className="formLabel">{field.label}</Grid>
                      <Grid item xs={12} sm={12} className={classes.inputWrapper}>
                        {fieldToShow == 'select' && 
                          <Select
                              name={option}
                              value={value}
                              onChange={(e) => this.handleChange(option, e)}
                              className={classes.summarySelect}
                              disabled={underwriterCannotEdit}
                          >
                            {field.choices.map((obj, idx) => {
                              let value = obj[0],
                                  label = obj[1];
                              return (
                                <MenuItem value={value}>{label}</MenuItem>
                              )
                            })}
                          </Select>
                        }
                        {fieldToShow == 'checkbox' && 
                          <div className={classes.checkboxInline}>
                              <Checkbox
                                checked={value}
                                onChange={(e) => this.handleOnCheckboxChange(option, e)}
                                disabled={underwriterCannotEdit}
                              />
                            </div>                       
                        }
                        {fieldToShow == 'date' && 
                          <label className={classes.labelForDatePicker}>
                              <MuiPickersUtilsProvider className={classes.pickerContainer} utils={DateFnsUtils}>
                                  <DatePicker
                                      format="yyyy-MM-dd"
                                      value={value}
                                      onChange={(date) => this.handleDateChange(date, option)}
                                      disabled={underwriterCannotEdit}
                                  />
                                  <IconButton>
                                      <img src={CalendarIcon} className={classes.buttonIcon}/>
                                  </IconButton>
                              </MuiPickersUtilsProvider>
                              </label>
                        }
                        {fieldToShow == 'autocomplete' && 
                          <AutoComplete
                            list="users"
                            value={value}
                            data={usersToUse}
                            update={(value) => this.handleAutoCompleteChange(option, value) }
                            underwriterCannotEdit={underwriterCannotEdit}
                          />
                        }
                        {fieldToShow == 'text' && 
                          <TextField
                            value={value}
                            fullWidth
                            id={option}
                            name={option}
                            onChange={(e) => this.handleChange(option, e)}
                            disabled={underwriterCannotEdit}
                          />
                        }
                      </Grid>
                    </Grid>  
                  )
                })}
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
  componentWillReceiveProps(newProps) {
    const oldProps = this.props;
    if(oldProps.formFields !== newProps.formFields) {
        this.setState({
          formFields: newProps.formFields,
          item: newProps.item
        });
    }
  }
}

TabForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TabForm);