import React from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import AppModal from "../../components/Modals/AppModal"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import PlanAutocomplete from "../../components/Fields/PlanAutocomplete";

import AccountService from '../../utils/AccountService';

// API
import API from "../../utils/API";
import ProfileService from "../../utils/ProfileService";
import AutoComplete from "../../components/Fields/AutoComplete";

const styles = theme => ({
  base: {},
  root: {},
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  paperScroll: {
    overflow: 'visible',
    position: 'relative',
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit * 2,
  },
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    borderRadius: 24,
    fontSize: 18,
    width: 150,
    justifyContent: 'space-between',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#27cfda'
    }
  },
  planModBtn: {
    width: 235,
  },
  previousButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent'
    }
  },
});

let baseLink = "/plans/";

class SelectPlanToModify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planList: [],
      plan: null,
      planId: null,
      isVendor: false,
      modalOpen: false,
      modalMessage: '',
    };
  }

  componentDidMount() {
    this.checkUserRole();
  }

  // TODO: Uncomment and amend once profile object has been updated to return user role
  checkUserRole = () => {
      let isVendor = false;
      let isNotEditor = false;
      let role = ProfileService.returnCurrentRole()

      if (role === 'Vendor') {
        isVendor = true;
        isNotEditor= true;
      }
      
      if (!role || role === 'Underwriter') {
        isNotEditor= true;
      }

    if (!isVendor) {
      window.location.href = '/plan-modifications';
      return;
    }
    this.setState({isVendor});
  }

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value, edit: true});
  };

  handleAutoCompleteChange = (name, option) => {
    if (name === 'plan') {
      this.setState({
        [name]: option,
        planId: option.id
      });
    }
  }

  render() {
    const {classes} = this.props;
    const {plan, planId} = this.state;
    return (
      <div className={classes.base}>
        <AppModal open={this.state.modalOpen} textMessage={this.state.modalMessage}
                  handleClose={this.handleModalClose}/>
        <Grid container component="main" alignItems={'center'} className={classes.root}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h1" className="pageTitle">
              Request Modification
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className={classes.form} noValidate>
              <Paper className={classes.paperScroll}>
                <Grid container className={classes.formcontainer}>
                  <Grid item xs={12} sm={6}>
                    <Typography component="h3" className={classes.paperTitle}>Choose Plan</Typography>
                  </Grid>
                </Grid>
                  <Grid item xs={6} style={{marginTop: 20}} align="left">
                    <PlanAutocomplete
                      value={plan}
                      list="plans"
                      type='/plans/'
                      update={(value) => this.handleAutoCompleteChange('plan', value)}
                    />
                  </Grid>
                <Divider className='searchLine__bottom'/>
                <Grid item xs={6} style={{marginTop: 80}} align="left">
                  {
                    planId &&
                    <IconButton
                      component={Link}
                      to={`/plan-modifications/add/${planId}/`}
                      className={`${classes.blueButton} ${classes.planModBtn}`}
                    >
                      Request Modification
                      <KeyboardArrowRightIcon className={classes.dropdownIcon}/>
                    </IconButton>
                  }
                  <IconButton
                    href="/plan-modifications"
                    className={`${classes.blueButton} ${classes.previousButton}`}>
                    Cancel
                  </IconButton>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SelectPlanToModify.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SelectPlanToModify));