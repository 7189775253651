import axios from 'axios';
import AccountService from './AccountService';

let token = AccountService.returnData(),
    liveUrl = AccountService.returnUrl();

export default axios.create({
  baseURL: liveUrl + 'api/',
  responseType: "json",
  headers: {
    "Authorization" : "Token " + token
  }
});