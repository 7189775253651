import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// COMPONENTS
import { Grid, Typography, Button, Link, Paper, Toolbar, TextField, List, ListItem, ListItemText, Menu, MenuItem, FormControl, InputLabel, Select, OutlinedInput } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputBase from '@material-ui/core/InputBase';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import Pagination from 'material-ui-flat-pagination';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';

// ASSETS
import FilterIcon from '../../assets/icon-filters.png'

const styles = theme => ({
  form: {
    borderRadius: 5,
    fontWeight: 500,
    color: '#333',
    height: 40,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    fontFamily: 'Calibri',
  },
  iconButton: {
    height:40,
    width:40,
    alignSelf: 'center',
    marginRight: 15,
    color: theme.palette.primary.light
  },
  icon:{
    height: 22,
    width: 22
  },
  input: {
    flex: 1,
    color: '#333',
    fontSize: 14,
    fontWeight: 400
  },
  toolbar: {
    // borderBottom: '1px solid #e7edee',
    marginBottom: theme.spacing.unit,
    // position: 'sticky !important',
    // top: '0px'
    // "&::before": {
    //   content: '" "',
    //   position: 'absolute',
    //   left: 0,
    //   bottom:0,
    //   height: 1,
    //   width : 50,
    //   borderBottom: '1px solid magenta'
    // }
  },
  searchContainer: {
    borderRight:'1px solid #e7edee',
  },
  perPage: {
    color: theme.palette.primary.main,
    fontSize: 18,
    display: 'inline-flex',
  },
  perPageNav: {
    display: 'inline-flex',
    padding: 0,
    marginLeft: theme.spacing.unit*1
  },
  perPageSelect: {
    backgroundColor: '#f2f7f8 !important',
    '&:hover': {
      backgroundColor: '#f2f7f8 !important'
    }
  },
  dropdownIcon: {
    color: '#cdd0da',
    marginLeft: theme.spacing.unit*1
  },
  filterContainer: {
    // position: 'relative'
    display: 'flex',
    justifyContent: 'flex-end'
  },
  fixedDrawer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 10,
    padding: 0,
    bottom: 0,
    maxWidth: 300
  },
  closedDrawer: {
    position: 'absolute',
    visibility: 'hidden',
    top: '-16px',
    right: '-18px',
    zIndex: 0
  },
  filterIcon: {
    width: 25,
    height: 25,
    color: theme.palette.primary.main
  },
  leftFilterIcon: {
    marginRight: theme.spacing.unit*1,
  },
  rightFilterIcon: {
    color: '#27cfda',
    marginLeft: theme.spacing.unit*1
  },
  drawerHeader: {
    padding:'28px 30px'
  },
  drawerContent: {
    padding:'30px 30px 0',
  },
  drawerFooter: {
    padding:30,
  },
  filterResults: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 18,
  },
  formControl: {
    width:'100%',
    marginBottom: theme.spacing.unit*2,
    position: 'relative',
    "& label": {
      position: 'absolute',
      top: "50%",
      left: 14,
      transform: 'translateY(-50%) scale(1) !important'
    },
    '& > div > div > div[id^="select"]': {
      height: '100% !important',
      padding: '0 32px 0 14px !important',
      fontSize: '14px !important',
      fontFamily: 'Calibri, sans-serif',
      color: '#27498f !important'
    }
  },
  tooltipWrapper: {
    display: 'flex',
    paddingLeft: '10px',
  },
  tooltip: {
    fontFamily: '"Barlow", sans-serif',
  },
});


const options = [
  '5',
  '10',
  '25',
  '50',
  '100',
];


class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: {
        placeholder: 'Search plans',
      },
      anchorEl: null,
      selectedIndex: 1,
      filterOpen: false,
      filters: [],
      selected: {},
      searchItem: ""
    }

    if (props.labels) {
      this.state.labels = props.labels;
    }
  }

  // rows per page functionality
  handleClickRowPerPageMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClickRowPerPageItem = (event, index) => {
    this.props.handleRowsPerPage(parseInt(options[index], 10));
    this.setState({ selectedIndex: index, anchorEl: null });
  };

  handleCloseRowPerPageMenu = () => {
    this.setState({ anchorEl: null });
  };

  // filters functionality
  getFilters = (filter) => {
    let filterList = [];
    let yearList = []
    for(let i = 2019; i <= new Date().getFullYear() + 2; i++) {
      yearList.unshift({'label': i, 'value': i})
    }
    if (['medical-plans', 'pharmacy-plans', 'plans', 'small-plans'].includes(filter) ) {
      filterList.push({'label': 'Document Type', 'name': 'dt', 'options':[{'label':'None','value':''},{'label':'HMO','value':'HMO'},{'label':'POS','value':'POS'}]});
      filterList.push({'label': 'Plan Year', 'name': 'py', 'options': yearList});
      filterList.push({'label': 'Status', 'name': 'cs', 'options':[{'label':'None','value':''},{'label':'CBIA','value':'CBIA'},{'label':'Large Group','value':'Large Group'},{'label':'Middle Market','value':'Middle Market'},{'label':'Muni','value':'Muni'},{'label':'Shop','value':'Shop'}]});
      // filterList.push({'label': 'Vendor Plans', 'name': 'vendorPlans', 'options':[{'label':'None','value':''},{'label':'Is Vendor Plan','value':'Is Vendor Plan'},{'label':'Is Not Vendor Plan','value':'Is Not Vendor Plan'}]});
    }
    if(filter === 'users') {
      filterList.push({'label': 'Staff Status', 'name': 'staff', 'options' :[{'label':'Yes','value':'Yes'},{'label':'No','value':'No'}]})
      filterList.push({'label': 'Superuser Status', 'name': 'superuser', 'options' :[{'label':'Yes','value':'Yes'},{'label':'No','value':'No'}]})
      filterList.push({'label': 'Active Status', 'name': 'staff', 'options' :[{'label':'Yes','value':'Yes'},{'label':'No','value':'No'}]})
      filterList.push({'label': 'Group', 'name': 'group', 'options' :[{'label':'Editor','value':'Editor'},{'label':'Underwriter','value':'Underwriter'},{'label':'Vendor','value':'Vendor'}]})
    }
    return filterList;
  }

  handleFilterChange = (event) => {
    let { filters, selected } = this.state,
        name = event.target.name,
        value = event.target.value;
    for (let filter of filters) {
      if (filter.name == name) {
        filters.splice(filters.indexOf(filter),1);
      }
    }
    if (value) {
      filters.push({'name': name, 'value': value});
    }
    selected[event.target.name] = event.target.value;
    this.setState({
      filters,
      selected
    }, () => this.props.updateFilters(this.state.filters));
  };

  handleFilterClose = () => {
    this.props.updateFilters(this.state.filters);
    this.setState({ filterOpen: false });
  };

  handleFilterOpen = () => {
    if (this.props.label !== 'Users') {
      this.setState({ filterOpen: true });
    }
  };

  handleFilterUpdate = () => {
    this.handleFilterClose();
    this.setState({filters: [], selected: {}});
  }

  // search functionality
  handleSearchFieldKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSearch(e);
    }
  }

  handleSearch = (e) => {
    let { searchItem } = this.state;
    this.props.search(searchItem);
  }

  handleUpdateSearchItem = (e) => {
    let { searchItem } = this.state;
    searchItem = e.target.value;
    this.setState({searchItem});
  }

  resetSearch = () => {
    this.setState({
      searchItem: ''
    }, this.props.resetSearch)
  }


  render() {
    const { classes, label } = this.props;
    const { labels, anchorEl } = this.state;

    //i don't know how you will pass filter into searchbar
    //so i just take information from location

    const filter = this.props.location.pathname.slice(1);

    const filters = this.getFilters(filter);
    let drawerClass = classes.closedDrawer;

    if (this.state.filterOpen) {
      drawerClass = classes.fixedDrawer;
    }

    return (
      <Grid container alignItems={'center'} spacing={24} className={classes.toolbar}>
          <Grid item xs={12} sm={4} className={classes.searchContainer}>
            <form className={classes.form} noValidate>
              <Tooltip classes={{tooltip: classes.tooltip}} title="Search list">
                <IconButton className={classes.iconButton} aria-label="search" onClick={e => this.handleSearch(e)}>
                  <SearchIcon className={classes.icon}/>
                </IconButton>
                </Tooltip>
                <InputBase
                  className={classes.input}
                  placeholder={labels.placeholder}
                  inputProps={{ 'aria-label': 'Search' }}
                  value={this.state.searchItem}
                  onChange={(e) => this.handleUpdateSearchItem(e)}
                  onKeyDown={this.handleSearchFieldKeyDown}
                />
                <IconButton style={{display: !this.props.searchInput ? 'none' : '', color: 'rgb(193, 196, 199)'}} className={classes.iconButton} aria-label="clear" onClick={e => this.resetSearch(e)}>
                  <ClearIcon className={classes.icon}/>
                </IconButton>
            </form>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.perPage}>Per Page</Typography>
            <List component="nav" className={classes.perPageNav}>
              <ListItem
                button
                aria-haspopup="true"
                aria-controls="lock-menu"
                aria-label="Pagination"
                onClick={this.handleClickRowPerPageMenu}
                className={classes.perPageSelect}
              >
                <ListItemText
                  primary={options[this.state.selectedIndex]}
                  disableTypography
                />
                <KeyboardArrowDownIcon className={classes.dropdownIcon}/>
              </ListItem>
            </List>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleCloseRowPerPageMenu}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  selected={index === this.state.selectedIndex}
                  onClick={event => this.handleClickRowPerPageItem(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
          {filter &&
            <Grid item xs={12} sm={3} align="right" className={classes.filterContainer}>
              <Divider className='verticalLine verticalLineFilter' orientation="vertical" />
              <Tooltip classes={{tooltip: classes.tooltip}} title="Filter list">
                <IconButton style={{padding: "12px 0"}} onClick={this.handleFilterOpen}>
                    <img className={classes.filterIcon} src={FilterIcon} />
                </IconButton>
              </Tooltip>
              <Paper className={drawerClass}>
                <header align="left" className={classes.drawerHeader}>
                  <IconButton onClick={this.handleFilterClose} className={classes.filterResults}>
                    <KeyboardArrowLeftIcon className={classes.filterIcon + ' ' + classes.leftFilterIcon}/>
                    Filter Results
                  </IconButton>
                </header>
                <section className={classes.drawerContent}>
                  <form>
                    <Grid container>
                    {filters &&
                      <React.Fragment>
                      {filters.map((filter, idx) =>
                        <Grid item xs={12} align="center">
                          <FormControl variant="outlined" className={classes.formControl}>
                            {!this.state.selected[filter.name] &&
                            <InputLabel
                              ref={ref => {
                                this.InputLabelRef = ref;
                              }}
                              htmlFor="outlined-age-simple"
                            >
                              {filter.label}
                            </InputLabel>}
                            <Select
                              value={this.state.selected[filter.name]}
                              onChange={(e) => this.handleFilterChange(e)}
                              className={classes.filterSelect}
                              input={
                                <OutlinedInput
                                  labelWidth={this.state.labelWidth}
                                  className={classes.selectInput}
                                  name={filter.name}
                                  id="outlined-age-simple"
                                />
                              }
                            >
                              {filter.options.map((option,idx) =>
                                  <MenuItem value={option.value}>{option.label}</MenuItem>
                                )}
                            </Select>
                          </FormControl>
                        </Grid>
                        )}
                      </React.Fragment>
                    }
                    </Grid>
                  </form>
                </section>
                <footer className={classes.drawerFooter} align="right">
                  <IconButton onClick={this.handleFilterUpdate} className={classes.filterResults}>
                    Filter Results <KeyboardArrowRightIcon  className={classes.filterIcon + ' ' + classes.rightFilterIcon}/>
                  </IconButton>
                </footer>
              </Paper>
            </Grid>
          }
        </Grid>
      )
    };
  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    const oldProps = this.props;
    if (!nextProps.filters) {
      return;
    }
    if (!this.state.filters || this.state.filters.length != nextProps.filters.length) {
      let selected = {};
      for (let filter of nextProps.filters) {
        selected[filter.name] = filter.value;
      }
      this.setState({ filters: nextProps.filters, selected });
    }
  }
};

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SearchBar));
