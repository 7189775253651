import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter, Link, Route, Switch, } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';
import PdfModal from '../Modals/PdfModal'

// ASSETS
import CheckCircleOutlineIcon from '../../assets/icon-check-circle.png';
import PDFIcon from '../../assets/icon-preview.png';
import CopyIcon from '../../assets/icon-duplicate.png';

let counter = 0;
let baseLink = "/important-information/";
function createData(name) {
  counter += 1;
  return { id: counter, name, baseLink };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  return array;
  // const stabilizedThis = array.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = cmp(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  // return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

let rows = [
  { id: 'name', numeric: false, label: 'Name' },
];

class AdminTableHead extends React.Component {
  createSortHandler = property => event => {
    let keys = this.props.keys;
    for (let key of keys) {
      if (key.id == property && key.sort_disabled) {
        return;
      }
    }
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
    if (this.props.keys) {
      rows = this.props.keys;
    }
    return (
      <TableHead>
        <TableRow>
        {/* 
          <TableCell>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        */}
          {rows.map(
            (row, index) => (
              <TableCell
              key={row.id}
              align={row.numeric ? 'center' : 'left'}
              sortDirection={orderBy === row.id ? order : false}
              style={{borderBottom: this.props.type === 'summary_object' && rows.length - 1  === index ? '1px solid transparent !important' : ''}}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                  >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    IconComponent={KeyboardArrowUpIcon}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

AdminTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let AdminTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Nutrition
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

AdminTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

AdminTableToolbar = withStyles(toolbarStyles)(AdminTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing.unit * 3,
  },
  iconImage: {
    width: 17,
    height: '100%'
  },
  iconImageCheck: {
    width: 23,
    height: 23
  },
  table: {
    minWidth: 1020,
  },
  tableRowStyle: {
    height: '65px !important'
  },
  tableRow: {
    '&:last-child td': {
      borderBottom: 'none !important'
    }
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  checkboxCell: {
  },
  subtitleForTableCell: {
    color: '#aebcdc',
    fontFamily: 'Calibri',
    fontSize: 14,
    fontWeight: 400
  },
  nameCell: {
    width: '100%',
    fontSize: 16,
    paddingTop: theme.spacing.unit*3,
    paddingBottom: theme.spacing.unit*3
  },
  nameCellLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },  
  cellLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  publishCell: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    display: 'inline-block',
    minWidth: 100,
    padding: 5,
    borderRadius: 3,
    textAlign:'center',
  },
  draftCell:{ 
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    display: 'inline-block',
    minWidth: 100,
    padding: 5,
    borderRadius: 3,
    textAlign:'center',
  },
  awaitingCell:{ 
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    display: 'inline-block',
    minWidth: 100,
    padding: 5,
    borderRadius: 3,
    textAlign:'center',
  },
  actionIcon:{
    color: theme.palette.primary.main,
    marginRight: theme.spacing.unit*2,
    '&:hover': {
      color:theme.palette.primary.light
    }
  },
  tooltipWrapper: {
    display: 'flex',
    paddingLeft: '10px',
  },
  tooltip: {
    fontFamily: '"Barlow", sans-serif',
  },
});

class AdminTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'name',
    selected: [],
    data: [],
    page: 0,
    keys: null,
    rowsPerPage: 100,
  };

  constructor(props) {
    super(props);
    if (props.data) {
      this.state.data = props.data;
    }
    if (props.keys) {
      this.state.keys = props.keys;
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy }, () => {this.props.handleSort(order, orderBy)});
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleActionClick = (event, id, action) => {
    console.log('run')
    this.props.actionTaken({'action': action, 'id': id});
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  renderData = (obj, key, classes) => {
    if (!obj[key]) {
      return "";
    }
    let objId = key;
    if (obj[key].type == 'boolean') {
      let option = obj[key].label;
      if (!option) {
        return "";
      }
      return <div><img style={{marginRight: 10}} className={classes.iconImageCheck} src={CheckCircleOutlineIcon} /></div>
    }
    if (obj[key].type == 'button') {
      let cellStatus = classes.cell,
          label = obj[key].label;
      if (obj[key]['colour'] === 'primary'){
        cellStatus = classes.publishCell;
      }         
    
      if (obj[key]['colour'] === 'disabled'){
        cellStatus = classes.draftCell;
      }         
      
    
      if (obj[key]['colour'] === 'secondary'){
        cellStatus = classes.awaitingCell;
      }
      if (label) {
        return <div className={cellStatus}>{label}</div>
      }
      return "";
    }
    
    if (obj[key].type == 'link') {
      var label = obj[key].label;
      var id =  obj[key].id;
      var path = obj[key].path;
      return <Link to={path + id} className={classes.cellLink}>{label}</Link>
    }
    
    if (obj[key].type == 'file') {
      var path = obj[key].path;
      return <Tooltip title="Open File"><IconButton onClick={(e) => { console.log('run'); window.open(path, "_blank");}}><img className={classes.iconImage} src={PDFIcon}/></IconButton></Tooltip>
    }

    if (obj[key].isMultiple) {
      if (obj[key].type == 'actions') {
        let template = [],
            actions = obj[key].actions;
        for (let action of actions) {
          let actionText = "Duplicate";
          switch(action) {
            case "previewPdf":
              actionText = "Preview PDF";
              break
            case "requestModification":
              actionText = "Request Modification";
              break
          }
          template.push((<Tooltip classes={{tooltip: classes.tooltip}} title={actionText}>
                    <IconButton onClick={(e) => this.handleActionClick(e, obj.id, action)}>
                      {!this.props.isNotEditor && action == 'duplicate' &&
                        <IconButton className={classes.actionIcon}><img className={classes.iconImage} src={CopyIcon}/></IconButton>
                      }
                      {this.props.isVendor && action == 'requestModification' &&
                        <IconButton className={classes.actionIcon}><img className={classes.iconImage} src={CopyIcon}/></IconButton>
                      }
                      {action == 'previewPdf' &&
                        <IconButton className={classes.actionIcon}><img className={classes.iconImage} src={PDFIcon}/></IconButton>
                      }
                    </IconButton>
                    </Tooltip>))
        }
        return template;
      }
    }

    return obj[key];
  }

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page, keys } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const labelList = [];
    if (keys) {
      for (let key of keys) {
        labelList.push({
          id: key.id,
          numeric: key.numeric
        });
      }
    }
    console.log('ufheryfihef', this.props.type)
    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table  aria-labelledby="tableTitle" >
            <AdminTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              keys={keys}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                      className={this.props.type === 'summary_object' ? classes.tableRow : classes.tableRowStyle}
                    > 
                      {/* <TableCell width="60px" onClick={event => this.handleClick(event, n.id)} className={classes.checkboxCell}>
                        <Checkbox checked={isSelected} />
                      </TableCell> */}
                      {keys && 
                        <React.Fragment>
                        {labelList.map((item, index) => 
                            <React.Fragment>
                              <TableCell className={this.props.type === 'summary_object' && keys.length === 1 ? `${classes.tablecellSummary} ${classes.nameCell}` : keys.length === 1 ? classes.nameCell : this.props.type === 'summary_object' ? classes.tablecellSummary : ''} align={item.numeric ? 'center' :  'left'}>
                                  {this.renderData(n,item.id,classes)}
                                  {item.id === 'file_name' &&
                                  <div className={classes.subtitleForTableCell}>SummaryFile object(12392)</div>
                                  }
                              </TableCell>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      }
                      {!keys &&
                        <React.Fragment>
                          <TableCell component="th" scope="row" className={classes.nameCell}>
                            <Link to={n.baseLink + n.id} className={classes.nameCellLink}>
                              {n.name}
                            </Link>
                          </TableCell>
                        </React.Fragment>
                      }
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }

  componentWillReceiveProps(newProps) {
    const oldProps = this.props;
    if(oldProps.data !== newProps.data) {
        this.setState({
          data: newProps.data
        });
    }
  }
}

AdminTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminTable);
