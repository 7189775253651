import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, Link, Paper, Toolbar, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

let counter = 0;
function createData(name, available, mac_address, expires, last_activity) {

  counter += 1;
  return { id: counter, name, available, mac_address, expires, last_activity };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'mac_address', numeric: false, disablePadding: false, label: 'MAC Address' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'available', numeric: false, disablePadding: false, label: 'Available' },
  { id: 'last_activity', numeric: false, disablePadding: false, label: 'Last Activity' },
];

class SensorStatusTableHead extends React.Component {
  render() {
    const { order, orderBy, numSelected, rowCount } = this.props;

    return (
        <TableHead>
          <TableRow>
            {rows.map(
              row => (
                <TableCell
                  key={row.id}
                  align={row.numeric ? 'right' : 'left'}
                  padding={row.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  <TableSortLabel
                  >
                    {row.label}
                  </TableSortLabel>
                </TableCell>
              ),
              this,
            )}
          </TableRow>
        </TableHead>
    );
  }
}

SensorStatusTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%'
  },
  tableWrapper: {},
});

class SensorStatusTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'name',
    selected: [],
    data: [
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', true, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', true, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', true, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', true, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', true, '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
    ],
    page: 0,
    rowsPerPage: 5,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <SensorStatusTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell>{n.mac_address}</TableCell>
                      <TableCell component="th" scope="row">
                        {n.name}
                      </TableCell>
                      <TableCell>
                        {n.available ? 'Active' : 'Inactive'}
                      </TableCell>
                      <TableCell>{n.last_activity}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
    );
  }
}

SensorStatusTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SensorStatusTable);
