import React from 'react';
import { BrowserRouter, Link, Route, Switch, } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { LockOpen } from '@material-ui/icons';

import AlertDialog from '../alerts/Alert';

import Logo from '../../assets/connecticare-logo.png';
import LoginBackground from '../../assets/login-illustration.png';

import axios from 'axios';
import AccountService from '../../utils/AccountService';

const styles = theme => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: `url(${LoginBackground})`,
    backgroundColor: '#ebf4fb',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'center',
    backgroundPosition: 'center',
  },
  papercontainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width:'30%',
    marginBottom: theme.spacing.unit * 6,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 48,
    fontWeight: '700',
    marginBottom: theme.spacing.unit * 8
  },
  subTitle: {
    fontSize:30,
    fontWeight: '700',
    marginBottom: theme.spacing.unit * 4
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0),
    textTransform: 'initial',
    borderRadius: 20,
    minWidth: 100
  },
  forgotten: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 500
  },
  formRow: {
    marginBottom: theme.spacing.unit*3
  },
  loginText: {
    backgroundColor: 'transparent',
  }
});

class Login extends React.Component {
  // const classes = useStyles();

  state = {
    message: null,
    open: false,
    username: null,
    password: null
  }

  handleClose = () => {
    this.setState({'open': false});
  }

  handleLogin = (event) => {
    event.preventDefault();
    let username = this.state.username,
        password = this.state.password;
    if (!(username && password)) {
      this.setState({
          'message': 'You must complete the form correctly',
          'open': true
        });
      return false;
    }
    this.login(username, password);
    return false;
  }

  login = (username, password) => {
    let baseUrl = AccountService.returnUrl();
    axios({
      url: baseUrl + 'api-token-auth/', //your url
      method: 'post',
      responseType: 'json', // important
      data: {
        'username': username,
        'password': password
      }
    }).then((response) => {
        if (response.data && response.data.token) {
          localStorage.setItem("token", response.data.token);
          window.location.href="/";
          return;
        }
        this.setState({
            'message': 'There was an undefined issue in logging in.',
            'open': true
          });
    })
    .catch((error) => {
      // handle error
      this.setState({
          'message': 'Cannot login with the current details',
          'open': true
        });
    });
    
  }

  handleUpdate = (name, value) => {
    this.setState({[name]: value});
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleLogin(e);
    }
  }
  render() {
    const { classes } = this.props;
    const { open, message } = this.state;
    return (
      <React.Fragment>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={6} md={6} className={classes.image} />
        <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square className={classes.papercontainer}>
          <div className={classes.paper}>
            <Grid container justify={'center'}>
              <Grid item xs={6}>
                <img src={Logo} className={classes.logo} alt="logo" />
                <Typography component="h2" variant="h1" className={classes.title}>
                    Connecticare Administration
                </Typography>
                <Typography component="h2" variant="h2" className={classes.subTitle}>
                    Sign in
                </Typography>
                <Typography component="body1">
                    Welcome back. Please login to your account.
                </Typography>
                <form className={classes.form}  noValidate>
                <Grid container className={classes.formRow}>
                  <Grid item xs={12} sm={12} className="formLabel">Email</Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      name="email"
                      className={classes.loginText}
                      onKeyDown={this._handleKeyDown}
                      onChange={e => this.handleUpdate('username', e.target.value)}
                      />
                  </Grid>
                </Grid>
                <Grid container className={classes.formRow}>  
                  <Grid item xs={12} sm={12} className="formLabel">Password</Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      id="password"
                      name="password"
                      type="password"
                      onKeyDown={this._handleKeyDown}
                      className={classes.loginText}
                      onChange={e => this.handleUpdate('password', e.target.value)}
                      />
                  </Grid>
                </Grid>
                  {/*<FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />*/}
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Link to="/forgot-password" variant="body2" color="Primary" className={classes.forgotten}>
                        Forgot your password?
                      </Link>
                    </Grid>
                    <Grid item xs align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={this.handleLogin}
                      >
                        Log in
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <AlertDialog open={open} message={message} close={this.handleClose} />

    </React.Fragment>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);