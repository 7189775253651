import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true // No more ripple, on the whole application!
    }
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontSize: 35,
      fontWeight: 500,
      fontFamily: "Calibri, sans-serif",
    },
    h2: {
      fontSize: 28,
      fontWeight:400,
      color: '#27498f',
      fontFamily: "Calibri, sans-serif",
    },
    h3: {
      fontSize: 25,
      fontWeight: 400,
      fontFamily: "Calibri, sans-serif",
    },
    h4: {
      fontSize: 20,
      fontWeight: 400,
      fontFamily: "Calibri, sans-serif",
    },
    subtitle1: {
      fontSize: 18,
      fontFamily: "Calibri, sans-serif",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 500,
      fontFamily: "Calibri, sans-serif",
    },
    body1: {
      fontSize: 16,
      fontWeight: 500,
      fontFamily: "Calibri, sans-serif",
      color: '#27498f',
    },
    body2: {
      fontSize: 15,
      fontWeight: 400,
      fontFamily: "Calibri, sans-serif",
      color: '#27498f'
    },
    caption: {
      fontSize: 13,
      fontWeight: 400,
      color: 'rgba(53, 57, 68, .7)',
      fontFamily: "Calibri, sans-serif",
    }
  },
  palette: {
    common: {
      black: 'rgba(20, 21, 23, 1)',
      white: 'rgba(255, 255, 255, 1)'
    },
    background: {
      paper: 'rgba(255, 255, 255, 1)',
      default: '#f2f7f8'
    },
    primary: {
      light: '#27cfda',
      main: '#27498f',
      dark: '#f8c71b',
      contrastText: '#fff'
    },
    secondary: {
      light: '#27cfda',
      main: 'rgba(36, 10, 19, 1)',
      dark: 'rgba(36, 10, 19, 1)',
      contrastText: '#fff'
    },
    text: {
      primary: 'rgba(0, 0, 0, 1)',
      secondary: 'rgba(53, 57, 68, 1)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    }
  },
  shape: {
    borderRadius: 0
  },
  overrides: {
    MuiPaper:{
      rounded:{
        borderRadius: 10,
        padding: '15px 30px',
      },
      elevation2:{
        boxShadow: '10px 20px 40px 0px rgba(27,64,89,0.15)'
      },
      elevation24:{
        boxShadow: '10px 20px 40px 0px rgba(27,64,89,0.15)'
      }
    },
    MuiPopover:{
      paper:{
        border: 'none !important',
        boxShadow: '10px 20px 40px 0px rgba(27,64,89,0.15)',
        padding: 15
      }
    },
    // Typography
    MuiTypography: {
      gutterBottom: {
        marginBottom: '0.85em',
      }
    },
    // Link
    MuiLink: {
      root: {
        fontWeight: 400,
      },
    },

    // Table Title
    MuiTableSortLabel: {
      active: {
        color: '#98add8',
      },
      icon: {
        marginLeft: 0,
        marginRight: 0
      },
      root: {
        '&:focus': {
          color: '#27cfda'
        },
        '&:hover': {
          color: '#27cfda'
        }
      }
    },
    // Button
    MuiButton: {
      root: {
        minWidth: 100,
        paddingTop: 13,
        paddingRight: 10,
        paddingBottom: 15,
        paddingLeft: 10,
        boxShadow: 'none',
        fontSize: 15,
        fontWeight: 500,
        lineHeight: 1.2,
        letterSpacing: 1.25,
        fontFamily: "Calibri, sans-serif",
        '&:hover': {
          backgroundColor: 'none',
          color: '#27cfda'
        }
      },
      contained: {
        boxShadow: 'none',
      },
      outlined: {
        paddingTop: 12,
        paddingRight: 19,
        paddingBottom: 14,
        paddingLeft: 19,
      },
      sizeSmall: {
        fontSize: 12,
        minWidth: 'auto',
      },
      label: {
        fontFamily: "Calibri, sans-serif",
        textTransform: 'none'
      }
    },
    // Icon Button
    MuiIconButton: {
      root: {
        fontFamily: "Calibri, sans-serif",
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#27498f',
          '& img': {
            backgroundColor: 'transparent',
            color: '#27498f',
            // filter: 'brightness(0.25)'
          }
        },
      }
    },
    // Forms
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      },
      label: {
        fontSize:14,
        color:  '#27498f',
        fontWeight: 500
      }
    },
    MuiInputLabel: {
      animated: {
        transition: 'none'
      },
      formControl: {
        // top: 50
      },
      outlined: {
        position: 'absolute',
        marginBottom: '5px',
        fontSize: 14,
        fontFamily: "Calibri, sans-serif",
        color:'#27498f',
        borderRadius: 15,
        '&$focused':{
          color:'#27cfda',
        }
      },
      shrink: {
        transform: 'translate(14px, 20px) scale(1) !important',
        color: '#27498f !important'
      },

    },
    MuiPrivateNotchedOutline: {
      root: {
        top: 0,
      },
      legend: {
        display: 'none',
      }
    },
    MuiSelect: {
      root: {
        height: 40,
      },
      select: {
        borderRadius: 3,
        '&:focus':{
          borderRadius: 3,
          backgroundColor: '#f2f7f8 !important'
        }
      },
      selectMenu: {
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      },
      outlined: {
        borderRadius:15,
        '&:focus':{
          borderRadius: 15,
          backgroundColor: 'transparent !important'
        }
      }
    },
    // TabForm: {
    //   textarea: {
    //     width: '100%'
    //   }
    // },
    MuiInput: {
      input: {
        fontSize: '18px',
        fontWeight: 400,
        color: '#27498f',
        padding: '0 10px',
        backgroundColor: '#f2f7f8',
        border: '1px solid #e7edee',
        height: 40,
        borderRadius:3,
        fontFamily: "Calibri, sans-serif",
        '&:focus': {
          backgroundColor: 'rgba(53, 57, 68, .03)'
        },
        '&:disabled': {
          backgroundColor: '#f7f7f7'
        }
      },
      underline: {
        '&::before': {
          display: 'none',
        },
        '&::after': {
          display: 'none',
        }
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Calibri, sans-serif",
        fontSize: 18,
      },
      inputType: {
        height: 'auto',
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '15.5px 12px',
        fontSize: 13,
        '&:focus': {
          color: '#27498f !important',
          backgroundColor: 'transparent !important'
        }
      },
      notchedOutline: {
        borderRadius:24,
        borderColor:'#e7edee !important' 
      }
    },
    MuiSlider: {
      container: {
        display: 'block',
        width: '80%',
        margin: 'auto',
      },
      track: {
        height: 5,
      }
    },
    MuiRadio: {
      root: {
        marginRight: 20, 
      },
    },
    // Dialog
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(27,64,89,.70)',
      }
    },
    MuiDialog: {
      paper: {
        margin: 0,
        padding: 30,
        width: '100%',
        borderRadius: 10,
      },
      paperWidthXs: {
        maxWidth: 340
      },
      paperWidthSm: {
        maxWidth: 700
      },
      paperWidthMd: {
        maxWidth: 500,
      },
      paperWidthLg: {
        maxWidth: 800,
      },
      paperWidthXl: {
        maxWidth: 1000,
      },
      paperScrollPaper: {
        maxHeight: 'calc(100vh - 96px)',
      }
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        fontSize: 28,
        fontWeight: 300,
        marginBottom: 25,
      }
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        paddingBottom: 30,
      }
    },
    MuiDialogActions: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '15px 0 0'
      },
      action: {
        width: '100%',
        margin: 0,
        maxWidth: 185,
      }
    },
    // Card
    MuiCard: {
      root: {
        padding: 20,
        borderRadius: 5,
      }
    },
    MuiCardContent: {
      root: {
        padding: 0,
      },
    },
    MuiCardActions: {
      root: {
        padding: '25px 0 0',
        marginTop: 25,
        borderTop: '1px solid rgba(53, 57, 68, .2)',
      },
      action: {
        margin: 0,
        padding: 0,
        textAlign: 'left',
        '&:hover': {
          background: 'transparent',
        }
      },
    },
    // Pagination
    MuiFlatPagination: {
      root: {
        textAlign: 'center',
      },
      rootCurrent: {
        width: 28,
        height: 28,
        borderRadius: 14,
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: 0,
        backgroundColor: 'rgba(201, 110, 71, 1)',
        color: '#fff',
        '&:hover': {
          backgroundColor: 'rgba(201, 110, 71, 1)',
        }
      },
      rootStandard: {
        '&:hover': {
          background: 'transparent',
        }
      },
      rootEnd: {
        display: 'none'
      }
    },
    // Table
    MuiTable: {
      root: {
        width: '100%',
        fontFamily: "Calibri, sans-serif",
      }
    },
    MuiTableHead: {
      root: {
        borderTop: 'none',
        borderBottom: '1px solid #6d6f72',
      }
    },
    MuiTableFooter: {
      root: {
        backgroundColor: 'rgba(218, 104, 60, .1)',
      }
    },
    MuiTableRow: {
      root: {
        height: 80,
        '&$selected': {
          backgroundColor: '#e2eef6',
        }
      },
      head: {
        height: 60,
      }
    },
    MuiTableCell: {
      root: {
        fontSize: 15,
        color: '#27498f',
        padding: 5,
        borderBottom: '1px solid rgba(36, 10, 19, .1)',
        borderRight: '1px solid rgba(36, 10, 19, .1)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:hover': {},
        '&.success': {
          backgroundColor: '#b0e1b7',
        },
        '&.error': {
          backgroundColor: '#f7a4a7',
        },
        '&.large': {
          width: 245,
          padding: '15px 30px',
        },
        '&.has-icon': {
          padding: '15px 15px 15px 30px',
        }
      },
      head: {
        color: '#98add8',
        textTransform: 'uppercase',
        fontSize: 15,
        fontWeight: 400,
        borderRight: 'none',
        borderBottom: '1px solid #e7edee',
        padding:'10px 5px'
      },
      body: {
        fontSize: 16,
        color:'#27498f',
        padding:'10px 5px',
        borderRight: 'none',
        borderBottom: '1px solid #e7edee',
      },
      footer: {
        color: '#000',
        fontSize: 14,
        fontWeight: 400,
        borderTop: 'none',

        borderBottom: '1px solid rgba(36, 10, 19, .1)',
      },
    },
    // Tooltip
    MuiTooltip: {
      tooltip: {
        padding: '5px 7px 7px 7px',
        backgroundColor: 'rgba(53, 57, 68, 1)',
        borderRadius: 5,
        fontSize: 12,
        fontWeight: 400,
      }
    },
    MuiStepper:{
      root:{
        padding: '15px 0 20px',
      }
    },
    MuiStep: {
      horizontal: {
        marginLeft: 2,
        marginRight: 2,
        flex: 1,
        paddingTop: 5,
        paddingBottom: 10,
        borderBottom: '2px solid #6d6f72',
      },
      completed: {
        borderBottom: '2px solid #8bc34a',
        color:'#8bc34a'
      },
      active: {
        borderBottom: '2px solid #5cd5fb',
      }
    },
    MuiStepLabel: {
      label: {
        color: '#6d6f72',
        fontWeight: 500,
        textTransform: 'uppercase',
        '&$active': {
          color:'#5cd5fb'
        },
        '&$completed': {
          color: '#8bc34a'
        },
      }
    },
    MuiStepIcon:{
      root: {
        color: '#6d6f72',
        '&$active': {
          color:'#5cd5fb'
        },
        '&$completed': {
          color: '#8bc34a'
        },
      },
    },
    MuiStepConnector: {
      root: {
        display: 'none',
      }
    },
    label: {
      textTransform: 'uppercase',
      alignSelf: 'center',
      fontSize: 12,
      color: '#6d6f72'
    },
    pagetitle: {
      color: '#27498f',
      paddingLeft: 16,
      paddingBottom: 24,
    },
    MuiList: {
      padding: {
        padding: 8,
      },
    },
    MuiListItem: {
      button: {
        padding: 15,
        borderRadius: 3,
        color:'#fff',
        backgroundColor: '#fff !important'
      },
    },
    MuiListItemIcon: {
      root: {
        marginRight:15,
        color: '#27498f',
        maxHeight:22,
        maxWidth: 22,
      }
    },   
    MuiListItemText: {
      root: {
        color: '#27498f',
        padding: 0,
        fontFamily: "Calibri, sans-serif",
        fontWeight: 500,
        '&:hover': {
          color: '#27cfda'
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: "Calibri, sans-serif",
        height: 'auto'
      },
      gutters:{
        paddingLeft:10,
        paddingRight:10
      }
    },
    MuiCheckbox: {
      root: {
        color: '#27498f',
        marginRight: 15,
      },
      colorSecondary: {
        '&$checked': {
          color:'#27cfda'
        }
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: '#e7edee',
        marginBottom: 10,
        marginTop: 10
      }
    },
    MuiTab:{
      root: {
        fontFamily: "Calibri, sans-serif",
      },
      textColorPrimary: {
        color: '#27498f',
        opacity: 0.5,
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'left',
        minWidth: 'initial'
      },
      labelContainer: {
        position: 'relative',
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        padding: '6px 24px 6px 30px !important',
        '&::before':{
          content: '" "',
          width: 20,
          height: 20,
          border: '1px solid #27cfda',
          borderRadius: 3,
          position: 'absolute',
          left:0,
          top: 'calc(50% - 10px)',
        }
      },
      selected: {
        '&$textColorPrimary':{
          opacity: 1,
        },
        '& $labelContainer': {
          '&::before':{
            backgroundColor:'#27cfda'
          }
        }
      }
    },
    MuiPrivateTabIndicator:{
      colorPrimary: {
        height: 0,
      }
    },
    MuiPrivateTabScrollButton: {
      root: {
        color: '#27498f',
      }
    }
  }
});

export default theme;