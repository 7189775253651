import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// COMPONENTS
import { Grid, Typography, Button, IconButton, Link, Paper, Toolbar, TextField, List, ListItem, ListItemText, Menu, MenuItem, AppBar, Tabs, Tab } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputBase from '@material-ui/core/InputBase';
import SaveIcon from '../../assets/icon-save.png';
import PreviewIcon from '../../assets/icon-preview.png';
import DuplicateIcon from '../../assets/icon-duplicate.png';
import PrintIcon from '../../assets/icon-print.png';
import DeleteIcon from '../../assets/icon-delete.png';

import Pagination from 'material-ui-flat-pagination';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  form: {
    backgroundColor: '#e2eef6',
    borderRadius: 5,
    fontWeight: 500,
    color: '#333',
    height: 40,
    padding: '0 15px',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },
  iconButton: {
    height:16,
    width:16,
    alignSelf: 'center',
    marginRight: 15,
    color: theme.palette.primary.main
  },
  iconContainerOrdering: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  icon:{
    height:16,
    width:16,
  },
  input: {
    flex: 1,
    color: '#333',
    fontSize: 14,
    fontWeight: 400
  },
  toolbar: {
    marginBottom: theme.spacing.unit*2
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  toolbarIcon: {
    color: theme.palette.primary.main,
    height: 24,
    width: 21,
    marginRight: theme.spacing.unit*2,
  },
  tabNav: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none'
  },
  tooltipWrapper: {
    display: 'flex',
    paddingLeft: '10px',
  },
  tooltip: {
    fontFamily: '"Barlow", sans-serif',
  },
  hidden: {
    display: 'none',
  },
});

class EditToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    if (props.placeholder) {
      this.state.placeholder = props.placeholder;
    }
  }

  handleChange = (event, value) => {
      this.props.updateCurrentTab(value);
    };

  handleAction = (action) => {
    this.props.handleAction(action);
  }


  render() {
    const { classes, options, currentTab, isExpanded, isNotEditor, isVendor } = this.props;
    return (
      <Grid container justify={'flex-end'}
            className={classes.toolbar}
      >
        {options && options.tabs && !isExpanded &&
          <Grid item sm={12}  md={isNotEditor ? 9 : 8} lg={isNotEditor ? 10 : 9} xl={isNotEditor ? 11 : 10}>
            <AppBar position="static" className={classes.tabNav}>
              <Tabs
                value={currentTab}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
              >
                {options.tabs.map((tab, index)=>
                    <Tab label={tab} />
                  )}
              </Tabs>
            </AppBar>
          </Grid>
        }
        <Grid item sm={12} md={isNotEditor ? 3 : 4} lg={isNotEditor ? 2 : 3} xl={isNotEditor ? 1 : 2} align="right" className={classes.iconContainer}>
          <Grid
          className={classes.iconContainerOrdering}
          >
            {options && options.actions &&
              <React.Fragment>
              <Divider className='verticalLine' orientation="vertical" />
                {options.actions.map((action, index) => {
                  let actionText = "",
                      actionImage,
                      hidden = false;
                  switch(action) {
                    case "save":
                      actionText = "Save";
                      actionImage = SaveIcon;
                      hidden = isNotEditor ? true : false;
                      break
                    case "previewPdf":
                      actionText = "Preview PDF";
                      actionImage = PreviewIcon;
                      break
                    case "duplicate":
                      actionText = "Duplicate";
                      actionImage = DuplicateIcon;
                      hidden = isNotEditor ? true : false;
                      break
                    case "requestModification":
                      actionText = "Request Plan Modification";
                      actionImage = DuplicateIcon;
                      hidden = isVendor ? true : false;
                      break
                    case "print":
                      actionText = "Print";
                      actionImage = PrintIcon;
                      break
                    case "delete":
                      actionText = "Delete";
                      actionImage = DeleteIcon;
                      hidden = isNotEditor ? true : false;
                      break
                    default:
                      break;
                  }
                  return (
                    <Tooltip classes={{tooltip: classes.tooltip}} title={actionText} className={hidden ? classes.hidden : ''}>
                      <IconButton className={hidden ? classes.hidden : ''} style={options && !options.tabs? {padding: '12px 0'}: null} onClick={(e) => this.handleAction(action)}>
                            <img src={actionImage} className={classes.toolbarIcon}  />
                      </IconButton>
                    </Tooltip>
                    )
                  })}
              </React.Fragment>
            }
          </Grid>
        </Grid>
      </Grid>
      )
    }
  componentDidMount() {}

  componentWillReceiveProps(newProps) {
    const oldProps = this.props;
    if(oldProps.tabs !== newProps.tabs) {
        this.setState({
          tabs: newProps.tabs
        });
    }
  }
}

EditToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(EditToolbar));
