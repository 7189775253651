import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import axios from 'axios';

// API
import API from "../../utils/API";
import AccountService from '../../utils/AccountService';

// ASSETS
import illustration from '../../assets/dashboard-illustration.svg';
import SearchBar from '../../components/Fields/SearchBar';
import PaginationBar from '../../components/Fields/PaginationBar';
import AdminTable from '../../components/Fields/AdminTable';
import PdfModal from '../../components/Modals/PdfModal';

// COMPONENTS
import { Grid, Typography, Button, IconButton, Link, Toolbar, Divider } from '@material-ui/core';
import AddIconCircle from '@material-ui/icons/AddCircle';
// import ProjectCard from '../components/Cards/ProjectCard';
import Pagination from 'material-ui-flat-pagination';
import Paper from '@material-ui/core/Paper';
// import Filter from '../components/Filter';
// import CreateProjectModal from '../components/Modals/CreateProjectModal';

import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';

import SyncomsTable from '../../components/SyncomsTable';

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing.unit * 5.5,
    paddingBottom: theme.spacing.unit * 8.5
  },
  wrapper: {
    textAlign: 'center',
  },
  subNavWrapper: {
    marginBottom: theme.spacing.unit * 3,
  },
  subNavLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 1,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  subNavButton: {
    marginLeft: 'auto',
  },
  illustration: {
    display: 'block',
    margin: '0 auto 12px',
  },
  subTitle: {
    fontWeight: 300,
    marginBottom: 30,
  },
  newProjectButton: {
    borderRadius: 24,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  projectCard: {
    marginBottom: theme.spacing.unit * 4.25,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  pagination: {
    marginTop: theme.spacing.unit * 5
  },
  paper: {
    position: 'relative'
  },
  toolBar: {
    minHeight: 'auto',
    backgroundColor: '#2a0714',
    boxShadow: 'none',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingBottom: 30,
    }
  },
  dropdownIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1,
    display: 'none'
  },
  topContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 35
    }
  },
  bottomContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  filter: {
    marginLeft: theme.spacing.unit * 3,
  },
  filterLabel: {
    marginRight: theme.spacing.unit * 5,
    color: theme.palette.common.white,
  },
  pageTitle: {
    color:theme.palette.primary.main,
    fontWeight: 800,
    fontSize: 36,
    paddingTop: theme.spacing.unit*6,
    paddingBottom: theme.spacing.unit*6
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,   
    paddingBottom: theme.spacing.unit*1,   
    paddingLeft: theme.spacing.unit*2, 
    paddingRight: theme.spacing.unit*2,
    borderRadius: 24,
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#27cfda'
    }
  },
  buttonIcon: {
    marginLeft: 15,
  },
});

let baseLink = "/medical-plans/",
    tableKeys = [
      {'label':'Type', 'id': 'DocumentType', 'numeric': false, 'disablePadding': true, },
      {'label':'Document Title', 'id': 'Name', 'numeric': false, 'disablePadding': true, },
      {'label':'Plan Year', 'id': 'PlanYear', 'numeric': true, 'disablePadding': true, },
      {'label':'Is Vendor Plan', 'id': 'vendor_plan', 'numeric': true, 'sort_disabled': true, 'disablePadding': true, },
      {'label':'Status', 'id': 'custom_status', 'numeric': true, 'disablePadding': true, },
      {'label':'Action', 'id': 'actions', 'numeric': true, 'sort_disabled': true, 'disablePadding': true, },
    ],
    labels = {
      'singular': "Medical Plan",
      "plural": "Medical Plans",
      "placeholder": "Search Medical Plans"
    };


class PlanList extends SyncomsTable {
  constructor(props) {
    super(props);
    this.state.tableKeys = tableKeys;
    this.state.canExport = true;
    this.state.isExporting = false;
    this.setupParamsBasedOnLocation();
  }

  setupParamsBasedOnLocation = () => {
    let path = window.location.pathname;
    if (path.indexOf("medical") >= 0) {
      this.state.baseLink = "/medical-plans/";
      this.state.labels = {
        'singular': "Medical Plan",
        "plural": "Medical Plans",
        "placeholder": "Search Medical Plans"
      };
    }
    else if (path.indexOf("pharmacy") >= 0) {
      this.state.baseLink = "/pharmacy-plans/";
      this.state.labels = {
        'singular': "Pharmacy Plan",
        "plural": "Pharmacy Plans",
        "placeholder": "Search Pharmacy Plans"
      };
    }
    else if (path.indexOf("small") >= 0) {
      this.state.baseLink = "/small-plans/";
      this.state.labels = {
        'singular': "Small Plan",
        "plural": "Small Plans",
        "placeholder": "Search Small Plans"
      };
    } else {
      this.state.baseLink = "/plans/";
      this.state.labels = {
        'singular': "Plan",
        "plural": "Plans",
        "placeholder": "Search Plans"
      };
      this.state.canExport = false;
    }
  }

  getPdf = (token) => {
    return AccountService.returnUrl() + "api/plans/pdf/" + token;
  }

  handleExport = (event) => {
    if (this.state.isExporting) {
      return;
    }
    this.setState({isExporting: true}, () => {
      let url = this.getUrl(),
          urlList = url.split("?"),
          token = AccountService.returnData(),
          baseUrl = AccountService.returnUrl(),
          fileName = "plans-export.csv";
      url = baseUrl + "api" + urlList[0] + "export?" + urlList[1];
      axios({
        url: url, //your url
        method: 'post',
        responseType: 'blob', // important
        headers: {
          "Authorization" : "Token " + token
        }
      }).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', fileName); //or any other extension
         document.body.appendChild(link);
         link.click();
        this.setState({isExporting: false});
      });
    });
  }

  openModal(url) {
    console.log('here', url)
    this.setState(state => ({
      openPdf: true,
      pdfUrl: url
    }))
  }

  getPdfData = (id) => {
    let url = '/plans/' + id + "/pdf-token/";
    API.get(url)
      .then((res) => {
        if (res.data.success) {
          this.openModal(this.getPdf(res.data.code));
          return;
        }
        this.setState(state => ({
          modalOpen: true,
          modalMessage: res.data.error_message
        }))
      })
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
  }

  handleActionTaken(action) {
    if (action.action == 'duplicate') {
      this.props.history.push('/plans/add/' + action.id);
      return;
    }
    if (action.action == 'requestModification') {
      this.props.history.push('/plan-modifications/add/' + action.id);
      return;
    }
    if (action.action == 'previewPdf') {
      this.getPdfData(action.id);
      return;
    }
  }

  pdfHandleClose = () => {
    this.setState({
      openPdf: false
    })
  }

  getColourFromStatus = (status) => {
    if (status == "Draft") {
      return "disabled";
    }
    if (status == "Awaiting") {
      return "secondary";
    }
    return "primary";
  }

  updateItem(item) {
    item.vendor_plan = {'label': item.vendor_plan, 'type': 'boolean'};
    item.custom_status = {'label': item.plan_status, 'type': 'button', 'colour': this.getColourFromStatus(item.plan_status)};
    item.actions = {'isMultiple': true, 'type': 'actions', 'actions': ['duplicate','requestModification','previewPdf']};
    item.Name = {'label': item.Name, 'type': 'link', id: item.id, path:baseLink};
    return item;
  }
}

PlanList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(PlanList));