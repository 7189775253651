import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  dialogTitle: {
    color:theme.palette.primary.main,
    fontWeight: 600,
    fontFamily: '"Barlow", sans-serif',
  },
  dialogContainer: {
    borderBottom:'1px solid #0000001f'
  },
  dialogText: {
    fontSize: 16,
    color: '#6d6f72',
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0),
    textTransform: 'initial',
    borderRadius: 3,
    minWidth: 100,
    padding: 8,
    maxWidth: 'fit-content',
    letterSpacing: 'initial',
  },
  close: {
    color: theme.palette.primary.main,
    textTransform: 'initial',
    backgroundColor: 'transparent',
    letterSpacing: 'initial',
    maxWidth:'fit-content',
    borderRadius: 3,
    minWidth: 100,
    padding: 8,
    '&:hover': {
      color: theme.palette.common.white
    }
  }
});

class AlertDialog extends React.Component {
  state = {
    open: false,
  };

  constructor(props) {
    super(props);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.close();
  };

  componentWillReceiveProps(nextProps) {
    const oldProps = this.props;
    if (oldProps.open != nextProps.open) {
      this.setState({ open: nextProps.open });
    }
  }

  render() {
    const { classes } = this.props;
    const { message, actionRequired } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Error!"}</DialogTitle>
          <DialogContent className={classes.dialogContainer}>
            <DialogContentText id="alert-dialog-description"  className={classes.dialogText}>
              {message && 
                <React.Fragment>{message}</React.Fragment>
              }
              {!message && 
                <React.Fragment>There was an error in submitting this, please try again</React.Fragment>
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AlertDialog);