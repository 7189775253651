import React from 'react';
import { Route, withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { TrendingUp, SupervisorAccount, SettingsInputAntenna, ContactSupport, ExitToApp, PermIdentity, QueryBuilder } from '@material-ui/icons';

import CloseIcon from '@material-ui/icons/Close';
import Dashboard from '../pages/Dashboard';

import ImportantInformationList from '../pages/ImportantInformation/List';
import ImportantInformationItem from '../pages/ImportantInformation/Item';
// import ImportantInformationHistoryList from '../pages/ImportantInformation/History';

import IntroductionList from '../pages/Introduction/List';
import IntroductionItem from '../pages/Introduction/Item';
import IntroductionHistoryList from '../pages/Introduction/History';

import EmailList from '../pages/Email/List';
import EmailItem from '../pages/Email/Item';

import TemplateList from '../pages/Template/List';
import TemplateItem from '../pages/Template/Item';

import PlanList from '../pages/Plans/List';
import PlanItem from '../pages/Plans/Item';
import PlanHistoryList from '../pages/Plans/History';

import SummaryList from '../pages/Summaries/List';
import SummaryItem from '../pages/Summaries/Item';
import SummaryAdd from '../pages/Summaries/Add';

import SmallSummaryList from '../pages/SmallSummaries/List';
import SmallSummaryAdd from '../pages/SmallSummaries/Add';

import SummaryFileList from '../pages/SummaryFiles/List';
import SummaryFileItem from '../pages/SummaryFiles/Item';
import SummaryFileHistoryList from '../pages/SummaryFiles/History';
import SummaryFileAdd from '../pages/SummaryFiles/Add';

import ModificationList from '../pages/PlanModifications/List';
import ModificationItem from '../pages/PlanModifications/RefactoringItem';
import SelectPlanToModify from "../pages/PlanModifications/SelectPlanToModify";
import HistoryList from '../pages/History';

import Groups from '../pages/Groups/List';
import GroupItem from '../pages/Groups/Item';

import EventItem from '../pages/Events/Item';
import Sensors from '../pages/Sensors/List';
import SensorItem from '../pages/Sensors/Item';
import Users from '../pages/Users/List';
import UserItem from '../pages/Users/Item';
import UserActivity from '../pages/Users/Activity';
import Profile from '../pages/Users/Profile';
import Support from '../pages/Support';

// IMAGES
import MedicalPlans from '../assets/menu-medical_plans.png';
import PharmacyPlans from '../assets/menu-pharmacy_plans.png';
import Plans from '../assets/menu-plans.png';
import SmallGroupPolicies from '../assets/menu-small_group_policies.png';
import Summaries from '../assets/menu-summaries.png';
import PlanModifications from '../assets/menu-plan_modifications.png';
import MenuCurve from '../assets/curve-menu.png';

const styles = theme => ({
  root: {
    display: 'flex',
    marginTop: 84,
    backgroundColor: theme.palette.background.default,
    minHeight: 'calc(100vh - 120px)'
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  shortDrawer: {
    width: 240,
    flexShrink: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  closeButton: {
    position: 'absolute',
    right: '-24px',
    top: 48,
    backgroundColor: theme.palette.primary.white,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    '&:hover':{
      backgroundColor: theme.palette.common.white,
    },
    '&:before': {
      content: "''",
      backgroundImage:`url(${MenuCurve})`,
      height: 108,
      width: 36,
      position: 'absolute',
      top: -34,
      left:0,
      zIndex:-1,
      backgroundSize:'26px 100%',
      backgroundRepeat: 'no-repeat',
    },
  },
  closeIcon: {
    paddingRight: 1,
    color: theme.palette.primary.light,
     '&:hover':{
      color: theme.palette.primary.main,
    },
  },
  toolbar: {
  },
  drawerPaper: {
    backgroundColor: theme.palette.common.white,
    borderRight: 'none',
    left: 'auto',
    top: 'auto',
    overflow: 'visible',
    width: 210,
    display: 'flex',
    justifyContent: 'space-between'
  },
  shortDrawerPaper: {
    backgroundColor: theme.palette.common.white,
    borderRight: 'none',
    left: -205,
    top: 'auto',
    overflow: 'visible',
    width: 210,
    display: 'flex',
    justifyContent: 'space-between'
  },
  content: {
    flexGrow: 1,
    paddingRight: 15,
    paddingLeft: 15,
    fontFamily: "Calibri, sans-serif",
    width: 'calc(100% - 240px)'
  },
  listitem: {
    '&:hover': {
      // color:'#fff',
      '& $listitemicon':{
        // color:'#fff',
      },
      '& $listitemtext':{
        // color:'#fff',
      }
    }
  },
  listitemicon: {
    maxWidth: 30,
    maxHeight: 40,
    '& img': {
      maxWidth:'100%',
      height: 'auto'
    }
  },
  listitemtext: {},
  detailRow: {
    marginBottom:15,
    display: 'flex',
    alignItems: 'center'
  },
  footerDetails: {
    fontFamily: "Calibri, sans-serif",
    fontSize:12,
    color:'#1b4059',
    marginBottom:60
  },
  icon: {
    marginRight:15,
    height:15,
    width: 'auto',
  }
});

class Content extends React.Component {
  constructor(props) {
    super(props);
    let primaryNav = [
      {'text': 'Medical Plans', 'icon': <img src={MedicalPlans}/>, 'link': '/medical-plans'},
      {'text': 'Pharmacy Plans', 'icon': <img src={PharmacyPlans}/>, 'link': '/pharmacy-plans'},
      {'text': 'Plans', 'icon':<img src={Plans}/>, 'link': '/plans'},
      {'text': 'Small Group Policies', 'icon': <img src={SmallGroupPolicies}/>, 'link': '/small-plans'},
      {'text': 'Summaries', 'icon': <img src={Summaries}/>, 'link': '/summaries'},
      {'text': 'Ind and Small Group Summaries', 'icon': <img src={Summaries}/>, 'link': '/small-summaries'},
      {'text': 'Plan Modifications', 'icon': <img src={PlanModifications}/>, 'link': '/plan-modifications'},
    ];
    let secondaryNav = [
      {'text': 'Sign out', 'icon': <ExitToApp />, 'link': '/logout'},
    ];
    this.state = {
      drawerOpen: true,
      primaryNav: primaryNav,
      secondaryNav: secondaryNav
    };
    if (!localStorage.getItem("token")) {
      // this.props.history.push('/login/');
      return;
    }
    // this.modal = React.createRef();
  }

    handleDrawerToggle = () => {
      this.setState(state => ({ drawerOpen: !this.state.drawerOpen }));
    };
  render() {
    const { classes, theme } = this.props;
    const { primaryNav, secondaryNav, drawerOpen } = this.state;

    var path = window.location.pathname;

    console.log (path);

    const drawer = (
      <div>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleDrawerToggle}>
          {drawerOpen? <CloseIcon className={classes.closeIcon}/>: <MenuIcon className={classes.closeIcon}/>
          }
        </IconButton>
        <div className={classes.toolbar} />
          <List>
            {primaryNav && primaryNav.map((item, index) => (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.link}
              className={classes.listitem}
            >
              <ListItemIcon className={classes.listitemicon}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} disableTypography className={classes.listitemtext}/>
            </ListItem>
          ))}
          </List>
        <List>
          {secondaryNav && secondaryNav.map((item, index) => (
          <ListItem button key={item.text} component={Link} to={item.link} className={classes.listitem}>
            <ListItemIcon className={classes.listitemicon}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} disableTypography className={classes.listitemtext}/>
          </ListItem>
        ))}
        </List>
      </div>
    );

    return(
      <div className={classes.root}>
        <Drawer open={true} className={drawerOpen? classes.drawer: classes.shortDrawer} variant="persistent" classes={{paper: drawerOpen? classes.drawerPaper: classes.shortDrawerPaper}}>
          {drawer}
        </Drawer>

        <main className={classes.content} style={!drawerOpen? {position: 'absolute', left: 30, width: '98%'}: null}>
          <Route exact path="/" component={Dashboard} />

          <Route exact path="/important-information" component={ImportantInformationList} />
          <Route exact path="/important-information/:id(\d+)/history" component={() => <HistoryList title="important information" url="important-information" />} />
          <Route exact path="/important-information/:id(\d+)" component={ImportantInformationItem} />
          <Route exact path="/important-information/add" component={ImportantInformationItem} />

          <Route exact path="/introductions" component={IntroductionList} />
          <Route exact path="/introductions/:id(\d+)/history" component={() => <HistoryList title="important information" url="introductions" />} />
          <Route exact path="/introductions/:id(\d+)" component={IntroductionItem} />
          <Route exact path="/introductions/add" component={IntroductionItem} />

          <Route exact path="/emails" component={EmailList} />
          <Route exact path="/emails/:id(\d+)/history" component={() => <HistoryList title="Email" url="emails" />} />
          <Route exact path="/emails/:id(\d+)" component={EmailItem} />
          <Route exact path="/emails/add" component={EmailItem} />

          <Route exact path="/templates" component={TemplateList} />
          <Route exact path="/templates/:id(\d+)/history" component={() => <HistoryList title="Templates" url="templates" />} />
          <Route exact path="/templates/:id(\d+)" component={TemplateItem} />
          <Route exact path="/templates/add" component={TemplateItem} />

          <Route exact path="/plans" component={PlanList} />
          <Route exact path="/plans/:id(\d+)/history" component={() => <HistoryList title="Plan" url="plans" />} />
          <Route exact path="/plans/add" component={PlanItem} />
          <Route exact path="/plans/add/:id(\d+)" component={PlanItem} />
          <Route exact path="/plans/:id(\d+)" component={PlanItem} />

          <Route exact path="/medical-plans" component={PlanList} />
          <Route exact path="/medical-plans/:id(\d+)/history" component={() => <HistoryList title="Medical Plan" url="plans" />} />
          <Route exact path="/medical-plans/add" component={PlanItem} />
          <Route exact path="/medical-plans/:id(\d+)" component={PlanItem} />

          <Route exact path="/pharmacy-plans" component={PlanList} />
          <Route exact path="/pharmacy-plans/:id(\d+)/history" component={() => <HistoryList title="Pharmacy Plan" url="plans" />} />
          <Route exact path="/pharmacy-plans/add" component={PlanItem} />
          <Route exact path="/pharmacy-plans/:id(\d+)" component={PlanItem} />

          <Route exact path="/small-plans" component={PlanList} />
          <Route exact path="/small-plans/:id(\d+)/history" component={() => <HistoryList title="Small Plan" url="plans" />} />
          <Route exact path="/small-plans/add" component={PlanItem} />
          <Route exact path="/small-plans/:id(\d+)" component={PlanItem} />

          <Route exact path="/summaries" component={SummaryList} />
          <Route exact path="/summaries/:id(\d+)/history" component={() => <HistoryList title="summaries" url="summaries" />} />
          <Route exact path="/summaries/:id(\d+)" component={SummaryItem} />
          <Route exact path="/summaries/add" component={SummaryAdd}/>

          <Route exact path="/small-summaries" component={SmallSummaryList} />
          <Route exact path="/small-summaries/:id(\d+)/history" component={() => <HistoryList title="summaries" url="small-summaries" />} />
          <Route exact path="/small-summaries/:id(\d+)" component={SummaryItem} />
          <Route exact path="/small-summaries/add" component={SmallSummaryAdd}/>

          <Route exact path="/summary-files" component={SummaryFileList} />
          <Route exact path="/summary-files/:id(\d+)" component={SummaryFileItem} />

          <Route exact path="/plan-modifications" component={ModificationList} />
          <Route exact path="/plan-modifications/:id(\d+)/history" component={() => <HistoryList title="plan modifications" url="plan-modifications" />} />
          <Route exact path="/plan-modifications/add" component={SelectPlanToModify} />
          <Route exact path="/plan-modifications/add/:id(\d+)" component={ModificationItem} />
          <Route exact path="/plan-modifications/:id(\d+)" component={ModificationItem} />

          <Route exact path="/groups" component={Groups} />
          <Route exact path="/groups/:id(\d+)" component={GroupItem} />
          <Route exact path="/groups/:id(\d+)/history" component={() => <HistoryList title="groups" />} />

          <Route exact path="/sensors" component={Sensors} />
          <Route path="/sensors/:id" component={SensorItem} />
          <Route exact path="/users" component={Users} />
          <Route path="/users/:id/activity" component={UserActivity} />
          <Route exact path="/users/add" component={UserItem} />
          <Route exact path="/users/:id(\d+)" component={UserItem} />
          <Route exact path="/users/:id(\d+)/history" component={() => <HistoryList title="users" url="users"  />} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/support" component={Support} />
        </main>
      </div>
    )
  }
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, {
  withTheme: true
})(Content));
