import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 2,
  },
  listItem: {
    border: 'solid 1px #EEE',
    fontFamily: "Calibri, sans-serif",
    '&:hover':{
      color: '#fff',
      '& $question': {
        color: '#fff',
      },     
      '& $faqicon':{
        color: '#fff',
        border: '2px solid #fff',
      }
    }
  },
  faqicon: {
    color:theme.palette.primary.main, 
    border: '2px solid #1b4059',
    borderRadius: 3
  },
  question: {
    fontSize: 24,
    color: theme.palette.primary.main
  },
  answer: {
    fontSize: 16,
    color: '#333',
  }
});

class NestedList extends React.Component {
  state = {
    open: true,
    faqs: [
      {'question': 'Question 1', 'answer': 'Answer 1'},
      {'question': 'Question 2', 'answer': 'Answer 2'},
      {'question': 'Question 3', 'answer': 'Answer 3'},
    ]
  };

  handleClick = (index : any) => {
    if (!this.state[index]) {
      this.setState({ [index]: true });
    } else {
      this.setState({ [index]: false });
  }
  };

  render() {
    const { classes } = this.props;
    const { faqs } = this.state;

    return (
      <List component="nav" className={classes.root}>
        { faqs.map((question : any, index : any) => 
            <div className={classes.listItem}>
              <ListItem button onClick={() => { this.handleClick(index) } } >
                <ListItemText primary={question.question} disableTypography className={classes.question}/>
                {this.state[index] ? <ExpandLess className={classes.faqicon}/> : <ExpandMore className={classes.faqicon}/>}
              </ListItem>
              <Collapse in={this.state[index]} timeout="auto" unmountOnExit>
                <List component="div">
                  <ListItem className={classes.nested}>
                    <ListItemText primary={question.answer} disableTypography className={classes.answer}/>
                  </ListItem>
                </List>
              </Collapse>
            </div>
        ) }
      </List>
    );
  }
}

NestedList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NestedList);