const ProfileService = {
    returnCurrentRole: function() {
        let profile = this.getProfileData();
        if (!profile.role) {
            return "Vendor";
        }
        return profile.role;
    },
    getProfileData: function() {
        try {
            let profile = JSON.parse(localStorage.getItem("profile"));
            return profile;
        } catch(err) {
            return {};
        }
    }
};

export default ProfileService;
