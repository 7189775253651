import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// COMPONENTS
import { Chip, Grid, Typography, Button, IconButton, Link, Paper, Toolbar, TextField, List, ListItem, ListItemText, Menu, MenuItem } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputBase from '@material-ui/core/InputBase';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Pagination from 'material-ui-flat-pagination';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  form: {
    backgroundColor: '#e2eef6',
    borderRadius: 5,
    fontWeight: 500,
    color: '#333',
    height: 40,
    padding: '0 15px',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },
  iconButton: {
    height:16,
    width:16,
    alignSelf: 'center',
    marginRight: 15,
    color: theme.palette.primary.main
  },
  icon:{
    height:16,
    width:16,
    color: theme.palette.primary.main
  },
  input: {
    flex: 1,
    color: '#333',
    fontSize: 14,
    fontWeight: 400
  },
  count: {
    fontSize: 24,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  paginationLink: {
    minWidth: 'auto',
    color: theme.palette.primary.light,
    fontSize: 18,
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  paginationNav: {
    color: theme.palette.primary.light,
    height: 33,
    width: 23,
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  chip: {
    fontFamily: "Calibri, sans-serif",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    marginRight: theme.spacing.unit*1,
    width: 115,
    justifyContent: 'space-between'
  }
});


class PaginationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      labels: {
        plural: "Items",
        singular: "Item",
      },
      perPage: 10,
      maxPage: 1, // total page limit for items
      maxPageCount: 7, // total number of items to show in pagination
      total: 0, // total number of items
    }
    // set initial total
    if (props.total) {
      this.state.total = props.total;
    }
    // set initial labels for this page (if set)
    if (props.labels) {
      this.state.labels = props.labels;
    }
  }

  calculateMaxPage = (total, perPage) => {
    return Math.ceil(total / perPage);
  }

  getStartItem = (maxCount) => {
    let startItem = 1,
        pageLimit = this.state.maxPage,
        currentPage = this.state.currentPage;
    let offset = Math.floor(maxCount/2);
    if (currentPage > 1) {
      if (currentPage > pageLimit - offset) {
        startItem = pageLimit - maxCount + 1;
      } else if (currentPage > 1 + offset) {
        startItem = currentPage - offset;
      }
    }
    if (startItem < 1) {
      startItem = 1;
    }
    return startItem;
  }

  getPaginationOptions() {
    let itemList = [],
        maxCount = (this.state.maxPageCount > this.state.maxPage) ? this.state.maxPage : this.state.maxPageCount;
    let startItem = this.getStartItem(maxCount);
    
    if (maxCount > 1) {
      for (let index=startItem; index<startItem+maxCount; index++) {
        let itemDict = {'label': index, 'page': index};
        itemList.push(itemDict);
      }
    }
    return itemList;
  }

  resetPagination = (currentPage) => {
    // external call
    // start pagination from a given page, or the beginning
    if (!currentPage) {
      currentPage = 1;
    }
    this.setState({currentPage});
  }

  // handle pagination functions
  handlePageClick = (pageDict) => {
    // click on a specific page
    this.props.handlePagination(pageDict.page);
    this.setState({currentPage: pageDict.page});
  }

  handleNextPrevClick(direction) {
    // click on next / previous
    let page = this.state.currentPage;
    if (direction === 'prev' && page > 1) {
      page -= 1;
    }
    if (direction === 'next' && page < this.state.maxPage) {
      page += 1;
    }
    this.props.handlePagination(page);
    this.setState({currentPage: page});
  }

  // filters (if any exist)
  handleFilterDelete = (item) => {
    // delete filter
    this.props.removeFilters(item);
  }

  render() {
    const { classes, filters, isFooter } = this.props;
    const { total, labels, currentPage, maxPage } = this.state;
    const paginationOptions = this.getPaginationOptions();
    return (
      <Grid container style = {isFooter? {marginTop: 20}: null}>
        <Grid item xs={12} sm={3}>
            {!isFooter && 
              <Typography component="h3" className={classes.count}>
                {total} {total !== 1 && <React.Fragment>{labels.plural}</React.Fragment> }
                {total === 1 && <React.Fragment>{labels.singular}</React.Fragment> }
              </Typography>
            }
        </Grid>
        <Grid item xs={12} sm={5}>
          {filters && !isFooter &&
              <React.Fragment>
                {filters.map((label, index) => 
                  <Chip label={label.value} onDelete={this.handleFilterDelete} className={classes.chip} deleteIcon={<CloseIcon className={classes.icon}/>} />
                )}
              </React.Fragment>
            } 
        </Grid>
        {paginationOptions.length > 0 &&
          <Grid item xs={12} sm={4} align="right">
            {currentPage > 1 && 
              <IconButton onClick={(event)=>this.handleNextPrevClick('prev')}>
                <KeyboardArrowLeftIcon className={classes.paginationNav}/>
              </IconButton>
            }
              <React.Fragment>
                {paginationOptions.map((label, index) =>
                  <Button 
                    onClick={(event)=>this.handlePageClick(label)} 
                    className={classes.paginationLink} 
                    style={{color: +currentPage === +label.label ? '#27498E' : ''}}
                    >
                    {label.label}
                  </Button>
                  )}
              </React.Fragment>
            {currentPage < maxPage && 
              <IconButton onClick={(event)=>this.handleNextPrevClick('next')}>
                <KeyboardArrowRightIcon className={classes.paginationNav}/>
              </IconButton>
            }
          </Grid>
        }
      </Grid>
      )
    }
  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    const oldProps = this.props;
    if (oldProps.total != nextProps.total || oldProps.rowsPerPage != nextProps.rowsPerPage) {
      let perPage = nextProps.rowsPerPage;
      let maxPage = this.calculateMaxPage(nextProps.total, perPage);
      this.setState({ total: nextProps.total, maxPage, perPage });
    }
  }
}

PaginationBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(PaginationBar));