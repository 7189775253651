import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AutoComplete from '../Fields/AutoComplete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddBoxIcon from '@material-ui/icons/AddBox';

const styles = theme => ({
  root: {},
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 12,
    color: '#6d6f72'
  },
  textfield: {
    marginBottom: theme.spacing.unit * 1
  },
  addbutton: {
    justifyContent: 'left',
    marginBottom: theme.spacing.unit * 2,
    textTransform: 'initial',
    fontSize: 14,
    color: theme.palette.primary.main,
    minWidth: 'auto'
  },
  addicon:{
    marginRight: 15
  },
  removebutton: {
    border: '2px solid #1b4059',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    minWidth: 'auto',
    borderRadius: 3,
    textTransform: 'initial',
    fontSize: 14
  },
  removeicon:{
    marginRight: 15,
    height:18,
    width: 'auto',
  }
});

class NotificationForm extends React.Component {

  constructor(props) {
    super(props);
    let emailList = [
      'admin@jbcole.co.uk',
      'ollie@jbcole.co.uk',
    ];
    let numberList = [
      '07894563899',
      '07877946466',
    ]
    this.state = {
      emails: emailList,
      numbers: numberList
    };
    this.createEventModal = React.createRef();
    this.modal = React.createRef();
  }

  handleAdd = (type) => {
    let emails = this.state.emails,
        numbers = this.state.numbers;
    if (type == 'email') {
      emails.push("");
    }
    if (type == 'number') {
      numbers.push("");
    }
    this.setState({
      ...this.state, ...{
        emails: emails,
        numbers: numbers,
      }
    });
  }

  handleBin = (type, position) => {
    let emails = this.state.emails,
        numbers = this.state.numbers;
    if (type == 'email') {
      emails.splice(position, 1);
    }
    if (type == 'number') {
      numbers.splice(position, 1);
    }
    this.setState({
      ...this.state, ...{
        emails: emails,
        numbers: numbers
      }
    });
  }

  render() {
    const { emails, numbers } = this.state;
    const { classes } = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12}>
          <form className={classes.form} noValidate>
            <Typography variant="h2" gutterBottom>Notification Details</Typography>
            <Typography variant="body1" gutterBottom>
              Text snippet to explain what you need to do
            </Typography>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={3} className={classes.label}>Email address</Grid>
              <Grid item xs={12} sm={9}>
                {emails && 
                  <React.Fragment>
                      {emails.map((email, index) => 
                      <Grid container>
                        <Grid item xs={7}>
                          <TextField
                            required
                            fullWidth
                            id="name_of_event"
                            name="email"
                            value={email}
                            className={classes.textfield}
                          />
                        </Grid>
                        {index > 0 &&
                          <Grid item xs={5}>
                            <Button className={classes.removebutton} onClick={() => this.handleBin('email', index)}>
                              <DeleteOutlineIcon className={classes.removeicon}/> Remove
                            </Button>
                        </Grid>}
                    </Grid>
                  )}
                    <Grid container>
                      <Grid item xs={12}>
                        <Button className={classes.addbutton} onClick={() => this.handleAdd('email')}>
                          <AddBoxIcon className={classes.addicon}/> Add more
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={3} className={classes.label}>Mobile Number</Grid>
              <Grid item xs={12} sm={9}>
                {numbers && 
                  <React.Fragment>
                      {numbers.map((number, index) => 
                      <Grid container>
                        <Grid item xs={3}>
                          <AutoComplete list="codes"/>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            fullWidth
                            id="name_of_event"
                            name="phone_number"
                            value={number}
                            className={classes.textfield}
                          />
                        </Grid>
                        {index > 0 &&
                          <Grid item xs={5}>
                            <Button className={classes.removebutton} onClick={() => this.handleBin('number', index)}>
                              <DeleteOutlineIcon  className={classes.removeicon}/> Remove
                            </Button>
                        </Grid>}
                    </Grid>
                  )}
                    <Grid container>
                      <Grid item xs={12}>
                        <Button className={classes.addbutton} onClick={() => this.handleAdd('number')}>
                          <AddBoxIcon className={classes.addicon}/> Add more
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

NotificationForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotificationForm);