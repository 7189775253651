import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// API
import API from "../../utils/API";

// ASSETS
import illustration from '../../assets/dashboard-illustration.svg';
import SearchBar from '../../components/Fields/SearchBar';
import PaginationBar from '../../components/Fields/PaginationBar';
import AdminTable from '../../components/Fields/AdminTable';

// COMPONENTS
import { Grid, Typography, Button, IconButton, Toolbar, Divider } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle'
// import ProjectCard from '../components/Cards/ProjectCard';
import Pagination from 'material-ui-flat-pagination';
import Paper from '@material-ui/core/Paper';
// import Filter from '../components/Filter';
// import CreateProjectModal from '../components/Modals/CreateProjectModal';

import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';

import SyncomsTable from '../../components/SyncomsTable';

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing.unit * 5.5,
    paddingBottom: theme.spacing.unit * 8.5
  },
  wrapper: {
    textAlign: 'center',
  },
  dropdownIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1,
    display: 'none'
  },
  paper: {
    position: 'relative'
  },
  subNavWrapper: {
    marginBottom: theme.spacing.unit * 3,
  },
  subNavLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 1,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  subNavButton: {
    marginLeft: 'auto',
  },
  illustration: {
    display: 'block',
    margin: '0 auto 12px',
  },
  subTitle: {
    fontWeight: 300,
    marginBottom: 30,
  },
  newProjectButton: {
    borderRadius: 24,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  projectCard: {
    marginBottom: theme.spacing.unit * 4.25,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  pagination: {
    marginTop: theme.spacing.unit * 5
  },
  toolBar: {
    minHeight: 'auto',
    backgroundColor: '#2a0714',
    boxShadow: 'none',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingBottom: 30,
    }
  },
  topContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 35
    }
  },
  bottomContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  filter: {
    marginLeft: theme.spacing.unit * 3,
  },
  filterLabel: {
    marginRight: theme.spacing.unit * 5,
    color: theme.palette.common.white,
  },
  buttonIcon: {
    marginLeft: 15,
  },
  addNewInformButton: {
    padding: '0.8rem !important'
  }
});


let baseLink = "/emails/",
    tableKeys = [
      {'label':'Sender', 'id': 'sender', 'numeric': false, 'disablePadding': true, },
      {'label':'Receiver', 'id': 'receiver', 'numeric': false, 'disablePadding': true, },
      {'label':'Date Sent', 'id': 'created', 'numeric': false, 'disablePadding': true, },
      {'label':'Plan', 'id': 'plan', 'numeric': false, 'disablePadding': true, },
      {'label':'Summary', 'id': 'summary', 'numeric': false, 'disablePadding': true, },
    ],
    labels = {
      'singular': "Email",
      "plural": "Email",
      "placeholder": "Search Email"
    };



class EmailList extends SyncomsTable {
  constructor(props) {
    super(props);
    this.state.baseLink = baseLink;
    this.state.labels = labels;
    this.state.tableKeys = tableKeys;
  }

  updateItem(item) {
    item.name = {'label': item.name, 'type': 'link', id: item.id, path:baseLink};
    return item;
  }
}

EmailList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(EmailList));