import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    dialog: {
      maxWidth: '350px',
      margin: '0 auto'
    },
    actionButton: {
      textTransform: 'uppercase',
      backgroundColor: theme.palette.secondary
    }
  });

function AppModal(props) {
  const { classes } = props;

  //it's appmodal dialog which you have to put into component
  //required to display dialog as well as handleclose function  and {modalOpen, modalMessage} 
  //properties to component state but you can consider creating of appmodal 
  //hooked up to some kind of global state if you need make it more flexible.

  return (
    <div>
      <Dialog
        open={props.open}
        className={classes.dialog}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Message</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography component="p" variant="subtitle1"> {props.textMessage}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display: 'flex', justifyContent: 'center'}}>
          <Button onClick={props.handleClose} color="secondary" text>
            OК
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(AppModal)