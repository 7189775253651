import React from 'react';
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InlineBenefitTable from "./InlineBenefitTable";

import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AppModal from "../../components/Modals/AppModal"

import AutoComplete from '../../components/Fields/AutoComplete';

import EditToolbar from '../../components/Fields/EditToolbar';
import ErrorModal from '../../components/Modals/ErrorModal';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';
import IconHistory from '../../assets/icon-history.png';
import SaveIcon from '../../assets/icon-save.png';
import PreviewIcon from '../../assets/icon-preview.png';
import DuplicateIcon from '../../assets/icon-duplicate.png';
import PrintIcon from '../../assets/icon-print.png';
import DeleteIcon from '../../assets/icon-delete.png';
import AddBoxIcon from '@material-ui/icons/AddBox';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import MDeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Tooltip from '@material-ui/core/Tooltip';


// COMPONENTS
import { Divider } from '@material-ui/core';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// API
import API from "../../utils/API";

const styles = theme => ({
  base: {},
  root: {
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paperContainer: {
    position: 'relative'
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dropdownIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1,
    display: 'none'
  },
  iconButtonText: {
    fontSize: '1.2rem',
    color: '#27498f'
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,
    paddingBottom: theme.spacing.unit*1,
    paddingLeft: theme.spacing.unit*2,
    paddingRight: theme.spacing.unit*2,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit*0.5,
    borderRadius: 24,
    fontSize: 18,
    width: 150,
    justifyContent: 'space-between',
    fontSize: 18,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#27cfda'
    }
  },
  buttonIcon: {
    width: 19,
    height: 17
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  templateTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
  },
  formRow: {
    marginBottom: theme.spacing.unit*3
  },
  addIcon: {
    color: theme.palette.primary.main,
    height: '2rem',
    width: '2rem',
  },
  toolbarIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing.unit*2
  },
  tooltipWrapper: {
    display: 'flex',
    paddingLeft: '10px',
  },
  tooltip: {
    fontFamily: '"Barlow", sans-serif',
  },
});
let baseLink = "/templates/";


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "rgba(0, 0, 0, 0.27)" : "#f2f7f8",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "rgba(0, 0, 0, 0.07)" : "white",
  borderRadius: 5,
  padding: grid,
  width: '100%'
});

class TemplateItem extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.modal = React.createRef();
    this.errorModal = React.createRef();
    let item = {
      plans: [],
      sections: [
        {
          'id': this.getNewItemId(),
          benefits: [
            this.getSubItem('1')
          ]
        }
      ]
    };
    if (this.props.match.params.id) {
      item = null;
    }
    this.state = {
      value: 'true',
      item: item,
      displayBenefits: true,
      options: {
        // 'actions': ['save','previewPdf','duplicate','delete'],
        'actions': ['save','duplicate','delete'],
      },
      headerOptions: {
        'actions': ['add','duplicate','delete','move'],
      },
      rowOptions: {
        'actions': ['duplicate','delete','move'],
      },
      modalOpen: false,
      modalMessage: '',
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    if (!item) {
      setTimeout(() => this.getData());
    }
  }

  componentDidMount() {
    console.log('document', document.querySelector('.ck.ck-editor'))
  }

  onDragEnd(result) {
    // dropped outside the list
    console.log(result);
    console.log("innner drag");
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (result.type === "droppableItem") {
      let item = this.state.item;
      item.sections = reorder(item.sections, sourceIndex, destIndex);
      this.setState({
        item
      });
    } else if (result.type === "droppableSubItem") {
      const itemSubItemMap = this.state.item.sections.reduce((acc, item) => {
        acc[item.id] = item.benefits;
        return acc;
      }, {});

      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      const sourceBenefits = itemSubItemMap[sourceParentId];
      const destBenefits = itemSubItemMap[destParentId];

      let newItems = [...this.state.item.sections];

      /** In this case benefits are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedBenefits = reorder(
          sourceBenefits,
          sourceIndex,
          destIndex
        );
        newItems = newItems.map(item => {
          if (item.id === sourceParentId.toString()) {
            item.benefits = reorderedBenefits;
          }
          return item;
        });
        let item = this.state.item;
        item.sections = newItems;
        this.setState({
          item
        });
      } else {
        let newSourceBenefits = [...sourceBenefits];
        const [draggedItem] = newSourceBenefits.splice(sourceIndex, 1);

        let newDestBenefits = [...destBenefits];
        newDestBenefits.splice(destIndex, 0, draggedItem);
        newItems = newItems.map(item => {
          if (item.id === sourceParentId.toString()) {
            item.benefits = newSourceBenefits;
          } else if (item.id === destParentId.toString()) {
            item.benefits = newDestBenefits;
          }
          return item;
        });
        let item = this.state.item;
        item.sections = newItems
        this.setState({
          item
        });
      }
    }
  }

  getUrl = () => {
    let url = baseLink;
    if (this.props.match.params.id) {
      url += this.props.match.params.id;
    }
    return url;
  }

  async getData() {
    // Load async data.
    let url = this.getUrl();

    let apiData = await API.get(url)
     .catch((error) => {
       this.setState(state => ({
         modalOpen: true,
         modalMessage: error.response.data.error_message
       }))
     });
    //// Parse the results
    let item = apiData.data.item;
    item = this.updateIdsToString(item);

    // Set the clients
    this.setState({
      ...this.state, ...{
        item: item
      }
    });
  }

  updateIdsToString = (obj) => {
    for (let item of obj.sections) {
      item.id = item.id.toString();
      for (let benefit of item.benefits) {
        benefit.id = "b-" + benefit.id.toString();
      }
    }
    return obj;
  }
  updateIdsForSubmission = (obj) => {
    for (let item of obj.sections) {
      if (item.id.toString().indexOf("n-") >= 0) {
        item.id = null;
      } else {
        item.id = parseInt(item.id, 10);
      }
      for (let benefit of item.benefits) {
        if (benefit.id.toString().indexOf("n-") >= 0) {
          benefit.id = null;
        } else {
          benefit.id = parseInt(benefit.id.replace("b-",""), 10);
        }
      }
    }
    return obj;
  }

  handleAction = (action) => {
    if (action == 'save') {
      this.handleSave();
      return;
    }
    if (action == 'add') {
      this.handleAddNewRow();
      return;
    }
    if (action == 'delete') {
      this.handleDelete();
      return;
    }
  }

  handleAddNewRow = () => {
    let { item } = this.state;
    item.sections.push({
      'id': this.getNewItemId(),
      benefits: [this.getSubItem('1')]
    });
    this.setState({item});
  };

  getNewItemId = (option) => {
    let extendedString = "";
    if (option) {
      extendedString = "-" + option.slice(0,10);
    }
    return 'n-' + Date.now().toString() + extendedString;
  }

  handleHeaderAction = (action) => {
    if (action == 'save') {
      this.handleSave();
      return;
    }
    if (action == 'delete') {
      this.handleDelete();
      return;
    }
  }
  handleHeaderRowAction = (action, itemId) => {
    let { item } = this.state,
        sections = item.sections;
    if (action == "add") {
      for (let section of sections) {
        if (section.id == itemId) {
          section.benefits.push(this.getSubItem(itemId));
        }
      }
    } else if (action == "duplicate") {
      let newItem = {};
      for (let section of sections) {
        if (section.id == itemId) {
          newItem = Object.create(section);
          newItem.benefits = []
          for (let subItem of section.benefits) {
            let newSubItem = Object.create(subItem);
            newSubItem.id = this.getNewItemId(newSubItem.id);
            newItem.benefits.push(newSubItem);
          }
        }
      }
      newItem.id = this.getNewItemId();
      sections.push(newItem);
    } else if (action == "delete") {
      let idxToUse = -1;
      for (let idx=0; idx<sections.length; idx++) {
        if (sections[idx].id == itemId) {
          idxToUse = idx;
        }
      }
      if (idxToUse > -1) {
        sections.splice(idxToUse, 1);
      }
    }
    item.sections = sections;
    this.setState({item});
  }
  handleBenefitRowAction = (action, subItemId, parentItemId) => {
    let {item} = this.state;
    if (action == 'duplicate') {
      for (let section of item.sections) {
        if (section.id == parentItemId) {
          for (let subItem of section.benefits) {
            if (subItem.id == subItemId) {
              let newSubItem = Object.create(subItem);
              newSubItem.id = this.getNewItemId(subItemId);
              section.benefits.push(newSubItem);
            }
          }
        }
      }
      this.setState(item);
      return;
    }
    if (action == 'delete') {
      for (let section of item.sections) {
        if (section.id == parentItemId) {
          let idxToUse = -1;
          for (let idx=0; idx < section.benefits.length; idx ++) {
            if (section.benefits[idx].id == subItemId) {
              idxToUse = idx;
            }
          }
          if (idxToUse >= 0) {
            section.benefits.splice(idxToUse, 1);
          }
        }
      }
      this.setState(item);
      return;
    }
  }

  handleBenefitRowUpdate = (key, value, subItemId, parentItemId) => {
    let {item} = this.state,
        allValues = [],
        currentSections = item.sections;
    for (let section of currentSections) {
      // only run through items that aren't part of the current update
      for (let subItem of section.benefits) {
        if (subItem.id != subItemId) {
          allValues.push(subItem.benefit_id);
        }
      }
    }
    if (key == 'benefit_id' && allValues.indexOf(value) >= 0) {
      let message = "You cannot duplicate benefit IDs in this template",
          displayBenefits = false;
      this.setState({message, displayBenefits}, () => {
        this.openErrorModal();
        let displayBenefits = true;
        this.setState({displayBenefits});
      });
      return;
    }
    for (let section of item.sections) {
      if (section.id == parentItemId) {
        for (let subItem of section.benefits) {
          if (subItem.id == subItemId) {
            subItem[key] = value;
            if (key == "benefit_id" && !subItem['benefit_out']) {
              subItem['benefit_out'] = value;
            }
          }
        }
      }
    }
    this.setState({item});
  }

  getSubItem = (option) => {
    return {
      'id': this.getNewItemId(option)
    };
  }

  handleDelete = (e) => {
    // Load async data.
    let url = this.getUrl();
    API.delete(url)
      .then((res) => {
        this.props.history.push('/templates/');
      })
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
  }
  handleConfirm = (e) => {
    let { item }= this.state;
    item.confirm = true;
    this.setState({item}, (e) => {
      this.handleSave(e);
    });
  }
  handleSave = (e) => {
    let item = this.state.item,
        url = this.getUrl(true),
        itemToSubmit = JSON.parse(JSON.stringify(item));

    itemToSubmit = this.updateIdsForSubmission(itemToSubmit);

    if (!this.isValidItem(itemToSubmit)) {
      this.setState(state => ({
        modalOpen: true,
        modalMessage: "You must complete the form before continuing"
      }))
      return;
    }
    let dict = {
      'template': itemToSubmit
    }
    API.post(url, dict)
      .then((res) => {
        if (res.data.success) {
        this.props.history.push(baseLink);
          } else {
            this.setState(state => ({
              modalOpen: true,
              modalMessage: "Cannot save with the current details"
            }))
        }
      })
      .catch((error) => {
        if (error.response.data.type == 'plan') {
          let message = error.response.data.error_message;
          this.setState({message}, () => {
            this.openConfirmationModal();
          });
          return;
        }
        this.setState(state => ({
          modalOpen: true,
          modalMessage: "Cannot save with the current details"
        }))
      });
  }

  handleModalClose = () => {
    this.setState(state => ({
      modalOpen: false,
    }))
  }

  handleUpdate = (key, value) => {
    let item = this.state.item;
    item[key] = value;
    this.setState({ item });
  }

  handleHeaderUpdate = (key, value, itemId) => {
    let { item } = this.state;
    for (let section of item.sections) {
      if (section.id == itemId) {
        section[key] = value;
      }
    }
    this.setState({ item });
  }

  isValidItem = (item) => {
    return true;
  }

  handleAutoCompleteChange = (key, value) => {
    let {item} = this.state;
    item.plans.push({'plan': value.value, 'title': value.label});
    this.setState({item});
  }

  openConfirmationModal = () => {
    this.modal.current.handleClickOpen();
  }

  openErrorModal = () => {
    this.errorModal.current.handleClickOpen();
  }

  // chips
  handleDeleteChip = (id) => {
    let { item } = this.state,
        idxToUse = -1;
    for (let idx=0;idx<item.plans.length;idx++) {
      if (item.plans[idx].plan == id) {
        idxToUse = idx;
      }
    }
    if (idxToUse >= 0) {
      item.plans.splice(idxToUse, 1);
    }
    this.setState(item.plans);
  }


  render() {
    const { classes } = this.props;
    const { item, options, headerOptions, rowOptions, message, displayBenefits } = this.state;
    let id = this.props.match.params.id;
    return (
      <div className={classes.base}>
        <AppModal open={this.state.modalOpen} textMessage={this.state.modalMessage} handleClose={this.handleModalClose}/>
        <Grid container component="main" alignItems={'center'} className={classes.root}>
          <Grid item xs={9}>
            <Typography component="h2" variant="h1" className="pageTitle">
              Template
              <KeyboardArrowUpIcon className={classes.dropdownIcon}/>
            </Typography>
          </Grid>
          <Grid item xs={3} align="right">
            <IconButton component={Link} to={`/templates/${id}/history`} className={classes.blueButton}>
            History
            <img src={IconHistory} className={classes.buttonIcon}/>
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            {item &&
              <form className={classes.form} noValidate>
                <Paper className={classes.paperContainer}>
                  <Grid container className={classes.formcontainer}>
                    <EditToolbar options={options} handleAction={(action)=> this.handleAction(action)} />
                    <Divider className='searchLine searchLine__toolbar'/>
                  </Grid>
                  <Grid container style={{ marginTop: 15 }} className={classes.formcontainer}>
                    <Grid item xs={12} sm={6}>
                      <Typography component="h3" className={classes.paperTitle}>
                         Change Template
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.formRow}>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} sm={12} className="formLabel">Template Name</Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          fullWidth
                          id="name"
                          name="name"
                          value={item.name}
                          placeHolder="Enter title here"
                          onChange={e => this.handleUpdate('name', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.formRow}>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} sm={12} className="formLabel">Plans</Grid>
                      <Grid item xs={12} sm={12}>
                        <AutoComplete type='plans' full="true" update={(value) => this.handleAutoCompleteChange('plans', value) } />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.formRow}>
                    <Grid item xs={12} sm={12} className="formLabel">
                    {item.plans.map((plan, index) => (
                      <Chip
                          label={plan.title}
                          onDelete={e => this.handleDeleteChip(plan.plan)}
                          className={classes.chip}
                        />
                    ))}
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 15, alignItems: 'center' }} className={classes.formcontainer}>
                    <Grid item xs={12} sm={6}>
                      <Typography component="h3" className={classes.templateTitle}>
                         Template Structure
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.formRow}>
                    <Grid item xs={12}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="droppable" type="droppableItem">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            className={classes.dragContainer}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {item.sections.map((item, index) => (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                  <div>
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                    <Grid
                                    container
                                    style={{alignItems: 'center'}}
                                    spacing={16}
                                    >
                                      <Grid
                                      item xs={9}
                                      >
                                        <TextField
                                          required
                                          fullWidth
                                          id="section_name"
                                          name="section_name"
                                          value={item.section_name}
                                          onChange={e => this.handleHeaderUpdate('section_name', e.target.value, item.id)}
                                        />
                                      </Grid>
                                      <Grid item xs={3}>
                                        {headerOptions.actions.map((action, idx) => {
                                          let actionText = "Duplicate";
                                          switch (action) {
                                            case "delete":
                                              actionText = "Delete";
                                          }
                                                                                return (
                                                                                  <Tooltip title={actionText}>
                                                                                    <IconButton style={options && !options.tabs? {padding: '12px 0'}: null} onClick={(e) => this.handleHeaderRowAction(action, item.id)}>

                                                                                      {action == 'duplicate' &&
                                                                                          <FileCopyIcon className={classes.toolbarIcon}/>
                                                                                      }
                                                                                      {action == 'delete' &&
                                                                                          <MDeleteIcon className={classes.toolbarIcon}/>
                                                                                      }
                                                                                    </IconButton>
                                                                                  </Tooltip>
                                                                                  )
                                                                                  })}
                                          <Tooltip title="Move">
                                           <IconButton style={{padding: '12px 0'}} {...provided.dragHandleProps} >
                                            <OpenWithIcon     className={classes.toolbarIcon}/>
                                            </IconButton>
                                          </Tooltip>
                                      </Grid>

                                    </Grid>
                                      {displayBenefits &&
                                        <InlineBenefitTable
                                          benefits={item.benefits}
                                          type={item.id}
                                          handleBenefitRowAction={this.handleBenefitRowAction}
                                          handleBenefitRowUpdate={this.handleBenefitRowUpdate}
                                          handleBenefitRowAutoCompleteChange={this.handleBenefitRowUpdate}
                                        />
                                      }
                                      <Grid container>
                                        <Grid item xs={12} align="center">
                                          <IconButton onClick={(e) => this.handleHeaderRowAction('add', item.id)} className={classes.iconButtonText}>
                                            <AddBoxIcon className={classes.toolbarIcon}/> New Row
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    </div>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Grid>
                </Grid>
                  <Grid item xs={12} sm={12} align="center">
                    <IconButton style={options && !options.tabs? {padding: '12px 0'}: null} onClick={(e) => this.handleAction('add')}  className={classes.iconButtonText}>
                      <AddBoxIcon className={classes.addIcon} /> New Section
                    </IconButton>
                  </Grid>
                </Paper>
              </form>
            }
          </Grid>

          <ErrorModal message={message} innerRef={this.errorModal} />
          <ConfirmationModal message={message} innerRef={this.modal} confirm={this.handleConfirm} />

        </Grid>
      </div>
    );
  }
}

TemplateItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TemplateItem);
