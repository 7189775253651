import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AccountService from '../../utils/AccountService';
import { Document, Page, pdfjs } from 'react-pdf';


class ScrollDialog extends React.Component {

  state = {
    open: this.props.openPdf,
    pdfUrl: this.props.pdfUrl,
    scroll: 'body',
    dialogToInsertFrame: null,
    numPages: null,
    pageNumber: 1,
  };

  componentDidMount() {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }

  handleClose = () => {
    this.props.close()
    this.setState({ open: false });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  handleNextPage() {
    this.setState(state => ({
      pageNumber: state.pageNumber + 1 >= state.numPages ? state.numPages : state.pageNumber + 1
    }))
  }

  handlePreviousPage() {
    this.setState(state => ({
      pageNumber: state.pageNumber - 1 ? state.pageNumber - 1 : 1
    }))
  }

  render() {
    const { pageNumber, numPages, pdfUrl } = this.state;

    return (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">Pdf file</DialogTitle>
          <DialogContent>
            <DialogContentText ref={el => this.dialogToInsertFrame = el} style={{position: 'relative'}}>
                <Document
                  file={`${pdfUrl}`}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                  onLoadError={console.error}
                  className="document-pdf"
                >
                <Page pageNumber={pageNumber} />
                </Document>
            </DialogContentText>
            <DialogActions>
              <Button color="primary" onClick={() => this.handlePreviousPage()}>
                <ArrowLeftIcon/>
              </Button>
              <Button color="primary" onClick={() => this.handleNextPage()}>
                <ArrowRightIcon/>
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
    );
  }
}

export default ScrollDialog;