import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import SensorStatusTable from '../../components/ViewEvent/Sensors';
import EventCalendar from '../../components/ViewEvent/EventCalendar';

const styles = theme => ({
  root: {
    padding: 15,
    flexGrow:1,
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0'
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  row: {
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit* 3
  },
  pageTitle: {
    color:theme.palette.primary.main,
    fontSize: 36,
    fontWeight: 500,
    paddingLeft: theme.spacing.unit * 2,
  },

});

class EventItem extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    let eventDetails = {
      'name': 'PPY Lexus Bluewater',
      'campaign_location': 'London',
      'event_type': 'Exhibition',
      'date_string': '15 August 2019 - 13 September 2019',
      'time_string': '9:00 AM - 5:00 PM',
      'notifications': {
        'emails': ['john.doe@example.com','mary.smith@mail.com'],
        'phone_numbers': ['+447712345678'],
      }
    };
    this.state = {
      value: 'true',
      event: eventDetails
    };
  }

  handleChange = event => {
      this.setState({ value: event.target.value });
    };

  render() {
    const { classes } = this.props;
    const { event } = this.state;
    return (
      <div className={classes.root}>
        <Grid container component="main" className={classes.row}>
          <Grid item xs={9}>
            <Typography component="h2" variant="h1" className={classes.pageTitle}>
              Event Overview
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs={12} lg={10} xl={8}>
            <Paper className={classes.root}>
              <Grid container spacing={24} className={classes.formcontainer}>
                <Grid item xs={12}>
                  <Typography variant="h2">
                    Sensors
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SensorStatusTable />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2" >
                    Event Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={16}>
                    <Grid item xs={4}>
                      <Typography variant="h3" className={classes.label}>
                        Name of event
                      </Typography>
                      <Typography variant="body1">
                        {event.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h3" className={classes.label}>
                        Campaign Location
                      </Typography>
                      <Typography variant="body1">
                        {event.campaign_location}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h3" className={classes.label}>
                        Type of event
                      </Typography>
                      <Typography variant="body1">
                        {event.event_type}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2">
                    Dates and Times
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant="h3" className={classes.label}>
                        Start and end date
                      </Typography>
                      <Typography variant="body1">
                        {event.date_string}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h3" className={classes.label}>
                        Start and end times
                      </Typography>
                      <Typography variant="body1">
                        {event.time_string}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <EventCalendar />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2">
                    Notifications
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant="h3"  className={classes.label}>
                        Email Address
                      </Typography>
                      {event.notifications.emails && 
                        <React.Fragment>
                        {event.notifications.emails.map((label, index) => {
                          return (
                          <Typography variant="body1">
                            {label}<br />
                          </Typography>
                          );
                        })}
                        </React.Fragment>
                      }
                      {!event.notifications.emails && 
                        <Typography variant="body1">
                          None
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h3" className={classes.label}>
                        Mobile Number
                      </Typography>
                      <div>
                        {event.notifications.phone_numbers && 
                        <React.Fragment>
                        {event.notifications.phone_numbers.map((label, index) => {
                          return (
                          <Typography variant="body1">
                            {label}<br />
                          </Typography>
                          );
                        })}
                        </React.Fragment>
                      }
                      {!event.notifications.phone_numbers && 
                        <Typography variant="body1">
                          None
                        </Typography>
                      }
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" className={classes.newProjectButton}>
                    Edit Event
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

EventItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventItem);