import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AppModal from "../../components/Modals/AppModal"

import IconHistory from '../../assets/icon-history.png'

import EditToolbar from '../../components/Fields/EditToolbar';
import AdminTable from '../../components/Fields/AdminTable';

// API
import API from "../../utils/API";

const styles = theme => ({
  base: {},
  root: {},
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  paperContainer: {
    position: 'relative'
  },
  pageTitle: {
    color:theme.palette.primary.main,
    fontWeight: 800,
    fontSize: 36,
    paddingTop: theme.spacing.unit*6,
    paddingBottom: theme.spacing.unit*6
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,   
    paddingBottom: theme.spacing.unit*1,   
    paddingLeft: theme.spacing.unit*2, 
    paddingRight: theme.spacing.unit*2,
    borderRadius: 24,
    fontSize: 18,
    display: 'flex',
    width: 150,
    justifyContent: 'space-between',
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#27cfda',
      color: '#fff'
    },
    
  },
  buttonIcon: {
    width: 19,
    height: 17
  },
  formContent: {
    color: '#777777'
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  formRow: {
    borderBottom: '1px solid #e7edee',
    marginBottom: theme.spacing.unit*3
  }
});

let counter = 0;
let baseLink = "/summary-files/";
class SummaryItem extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {
      value: 'true',
      item: {},
      summary_item: {
        'name': '2020 POS-Std Cath Excl w/contraceptive-MCC'
      },
      options: {
        'actions': ['save', 'delete'],
      },
      modalOpen: false,
      modalMessage: '',
    };
    let data = [
      this.createData('Choice HMO.pdf', 'link-to-file', 'SBC Choice HMO.pdf', 'link-to-sbc-file'),      
      this.createData('Choice HMO.pdf', 'link-to-file', 'SBC Choice HMO.pdf', 'link-to-sbc-file'),     
      this.createData('Choice HMO.pdf', 'link-to-file', 'SBC Choice HMO.pdf', 'link-to-sbc-file'),      
    ]
    this.state.data = data;
    this.state.tableKeys = [
      {'label':'Name', 'id': 'name', 'numeric': false, 'disablePadding': true, },     
      {'label':'File', 'id': 'file', 'numeric': false, 'disablePadding': true, },     
      {'label':'SBC Name', 'id': 'sbc_name', 'numeric': false, 'disablePadding': true, },     
      {'label':' SBC File', 'id': 'sbc_file', 'numeric': false, 'disablePadding': true, },
      
    ]
    this.modal = React.createRef();  
    setTimeout(() => this.getData());
  }

  getUrl = () => {
    let url = baseLink;
    if (this.props.match.params.id) {
      url += this.props.match.params.id;
    }
    return url;
  }

  async getData() {
    // Load async data.
    let url = this.getUrl();
    
    let apiData = await API.get(url)
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
    // Parse the results
    let item = apiData.data.item;
    
    // Set the clients
    this.setState({
      ...this.state, ...{
        item: item
      }
    });
  }
  
  createData = (file_name, file_link, sbc_file_name, sbc_file_link) => {
    counter += 1;
    let data = { id: counter, file_name,sbc_file_name, };
    data.file_link = {'path': file_link, 'type': 'file', id: counter};   
    data.sbc_file_link = {'path': sbc_file_link, 'type': 'file', id: counter};
    return data;
  }


  handleChange = event => {
      this.setState({ value: event.target.value });
    };

    handleModalClose = () => {
      this.setState(state => ({
        modalOpen: false,
      }))
    }

  render() {
    const { classes } = this.props;
    const { item, tableKeys, data, options  } = this.state;
    return (
      <div className={classes.base}>
        <AppModal open={this.state.modalOpen} textMessage={this.state.modalMessage} handleClose={this.handleModalClose}/>
        <Grid container component="main" alignItems={'center'} className={classes.root}>
          <Grid item xs={9}>
          <Typography component="h2" variant="h1" className={classes.pageTitle}>
            Summary Files
          </Typography>
          </Grid>
          <Grid item xs={3} align="right">
          </Grid>
          {item && 
            <Grid item xs={12}>
              <form className={classes.form} noValidate>
                <Paper className={classes.paperContainer}>
                  <Grid container style={{marginTop: 10} } className={classes.formcontainer}>
                    <Grid item xs={12} sm={6}>
                      <Typography component="h3" style={{marginBottom: 30}} className={classes.paperTitle}>Summary File object ({item.serial_number})</Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.formRow}>
                      <Grid item xs={12} sm={2} className="formLabel">Name</Grid>
                      <Grid item xs={12} sm={10}>
                        <Typography component="p" className={classes.formContent}>{item.name}</Typography>
                      </Grid>
                  </Grid>             
                  <Grid container className={classes.formRow}>
                      <Grid item xs={12} sm={2} className="formLabel">File</Grid>
                      <Grid item xs={12} sm={10}>
                        <Typography component="p" className={classes.formContent}>{item.file}</Typography>
                      </Grid>
                  </Grid>                
                  <Grid container className={classes.formRow}>
                      <Grid item xs={12} sm={2} className="formLabel">Summary</Grid>
                      <Grid item xs={12} sm={10}>
                        <Typography component="p" className={classes.formContent}>{item.summary}</Typography>
                      </Grid>
                  </Grid>                
                  <Grid container className={classes.formRow}>
                      <Grid item xs={12} sm={2} className="formLabel">SBC Name</Grid>
                      <Grid item xs={12} sm={10}>
                        <Typography component="p" className={classes.formContent}>{item.sbc_name}</Typography>
                      </Grid>
                  </Grid>
                  <Grid container className={classes.formRow}>
                      <Grid item xs={12} sm={2} className="formLabel">SBC File</Grid>
                      <Grid item xs={12} sm={10}>
                        <Typography component="p" className={classes.formContent}>{item.sbc_file}</Typography>
                      </Grid>
                  </Grid>
                </Paper>
              </form>
            </Grid>
          }
        </Grid>
      </div>
    );
  }
}

SummaryItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SummaryItem);