import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

// API
import API from '../utils/API';
import ProfileService from '../utils/ProfileService';

// ASSETS
import SearchBar from './Fields/SearchBar'
import PaginationBar from './Fields/PaginationBar'
import AdminTable from './Fields/AdminTable'

import PdfModal from './Modals/PdfModal'

// COMPONENTS
import {
    Grid,
    Typography,
    Button,
    IconButton,
    Link,
    Toolbar,
    Divider
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
// import ProjectCard from '../components/Cards/ProjectCard';
import Pagination from 'material-ui-flat-pagination'
import Paper from '@material-ui/core/Paper'
import AppModal from './Modals/AppModal'
// import Filter from '../components/Filter';
// import CreateProjectModal from '../components/Modals/CreateProjectModal';

import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown'

class SyncomsTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            archived: 0,
            page: 1,
            clients: [],
            filterList: [],
            ordering: 'latest',
            tool: null,
            total: null,
            search: '',
            rowsPerPage: 10,
            baseLink: '/',
            labels: {},
            tableKeys: [],
            canAdd: true,
            canExport: false,
            openPdf: false,
            modalOpen: false,
            modalMessage: '',
            isVendor: false,
            isNotEditor: false,
        }
        let items = []
        this.state.items = items
        this.modal = React.createRef()
        this.paginationTop = React.createRef()
        this.paginationBottom = React.createRef()
    }

    // Handles the pagination
    handlePaginationClick(page, paginationItem) {
        paginationItem == 'top'
            ? this.paginationBottom.current.resetPagination(page)
            : this.paginationTop.current.resetPagination(page)
        this.setState(
            {
                page
            },
            this.getData
        )
    }

    handleActionTaken(action) {
        if (action === 'previewPdf') {
            this.setState(state => ({
                openPdf: true
            }))
        } else {
            this.setState(state => ({
                modalOpen: true,
                modalMessage: action
            }))
        }
    }

    removeFilter = item => {
        let filters = this.state.filterList
        filters.splice(filters.indexOf(item), 1)
        this.setState({ filterList: filters }, this.getData);
    }

    getUrl = () => {
        let url = this.state.baseLink,
            queryList = []
        if (this.state.search) {
            queryList.push('n=' + this.state.search)
        }
        if (this.state.page) {
            queryList.push('p=' + this.state.page)
        }
        if (this.state.orderBy) {
            queryList.push('o=' + this.state.orderBy)
        }
        if (this.state.rowsPerPage) {
            queryList.push('l=' + this.state.rowsPerPage)
        }
        if (this.state.filterList) {
            for (let filter of this.state.filterList) {
                queryList.push(filter.name + '=' + filter.value);
            }
        }
        if (queryList.length) {
            url += '?' + queryList.join('&')
        }
        return url
    }

    handleSort = (order, orderBy) => {
        this.setState({ orderBy: orderBy + '_' + order }, this.getData)
    }

    // filters

    handleUpdateFilters = (filters) => {
        this.setState({ filterList: filters }, this.getData);
    }

    async getData() {
        // Load async data.
        let url = this.getUrl()

        let apiData = await API.get(url).catch(error => {
            this.setState(state => ({
                modalOpen: true,
                modalMessage: error.response.data.error_message
            }))
        })

        // Parse the results
        let items = apiData.data.items,
            total = apiData.data.total
        for (let item of items) {
            this.updateItem(item)
        }

        const {isVendor, isNotEditor} = this.checkUserRole();

        // Set the clients
        this.setState({
            ...this.state,
            ...{
                items: items,
                total: total,
                isVendor,
                isNotEditor,
            }
        })
    }

    // TODO: Uncomment and amend once profile object has been updated to return user role
    checkUserRole = () => {
        let isVendor = false;
        let isNotEditor = false;
        let role = ProfileService.returnCurrentRole()

        if (role === 'Vendor') {
          isVendor = true;
          isNotEditor= true;
        }

        if (!role || role === 'Underwriter') {
          isNotEditor= true;
        }

        return {isVendor, isNotEditor};
    }

    resetPagination = () => {
        this.state.page = 1
        this.paginationTop.current.resetPagination()
        this.paginationBottom.current.resetPagination()
    }

    handleSearch = search => {
        this.resetPagination()
        this.setState({ search }, this.getData)
    }

    updateItem(item) {
        if (!item.group_name) {
            item.group_name = '-'
        }
        item.group_name = {
            label: item.group_name,
            type: 'link',
            id: item.id,
            path: this.state.baseLink
        }
        return item
    }

    pdfHandleClose() {
        this.setState(state => ({
            openPdf: false
        }))
    }

    pdfHandleOpen() {
        this.setState(state => ({
            openPdf: true
        }))
    }

    handleModalClose = () => {
        this.setState(state => ({
            modalOpen: false
        }))
    }

    resetSearch = () => {
        this.resetPagination()
        this.setState(
            state => ({
                search: ''
            }),
            this.getData
        )
    }

    handleRowsPerPage = (row) => {
      this.setState(state => ({
        rowsPerPage: row
      }), this.getData )
    }

    render() {
        const { classes } = this.props
        const {
            total,
            campaigns,
            archived,
            filterList,
            items,
            rowsPerPage,
            labels,
            tableKeys,
            openPdf,
            isVendor,
            isNotEditor,
            baseLink,
        } = this.state
        return (
            <React.Fragment>
                <AppModal
                    open={this.state.modalOpen}
                    textMessage={this.state.modalMessage}
                    handleClose={this.handleModalClose}
                />
                {openPdf && (
                    <PdfModal
                        openPdf={openPdf}
                        close={() => this.pdfHandleClose()}
                        pdfUrl={this.state.pdfUrl}
                    />
                )}
                <Grid container alignItems={'center'}>
                    <Grid item xs={12} sm={7}>
                        <Typography
                            component='h2'
                            variant='h1'
                            className='pageTitle'
                        >
                            {labels.plural}
                            <KeyboardArrowUpIcon
                                className={classes.dropdownIcon}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5} align='right'>
                        {this.state.canExport && (
                            <IconButton
                                onClick={this.handleExport}
                                className='blueButton margin10'
                            >
                                Export As CSV
                            </IconButton>
                        )}
                        {!isNotEditor && this.state.canAdd && (
                            <IconButton
                                href={this.state.baseLink + 'add'}
                                className='blueButton'
                            >
                                Add {labels.singular}{' '}
                                <AddCircleIcon className={classes.buttonIcon} />
                            </IconButton>
                        )}
                        {baseLink === '/plan-modifications/' && isVendor && (
                          <IconButton
                            href={this.state.baseLink + 'add'}
                            className='blueButton'
                          >
                              Add {labels.singular}{' '}
                              <AddCircleIcon className={classes.buttonIcon}/>
                          </IconButton>
                        )}
                    </Grid>
                    <Grid item xs={12} style={{ position: 'relative', minHeight: 365 }}>
                        <Paper className={classes.paper} style={{ minHeight: 365 }}>
                            <SearchBar
                                labels={labels}
                                filter='Medical Plan'
                                filters={filterList}
                                searchInput={this.state.search}
                                search={search => this.handleSearch(search)}
                                resetSearch={() => this.resetSearch()}
                                updateFilters={(filters) => this.handleUpdateFilters(filters)}
                                handleRowsPerPage={rowsPerPage =>
                                    this.handleRowsPerPage(rowsPerPage)
                                }
                            />
                            <Divider className='searchLine' />
                            <PaginationBar
                                innerRef={this.paginationTop}
                                labels={labels}
                                handlePagination={page =>
                                    this.handlePaginationClick(page, 'top')
                                }
                                isFooter={false}
                                rowsPerPage={rowsPerPage}
                                filters={filterList}
                                removeFilters={item => this.removeFilter(item)}
                                total={total}
                            />
                            <AdminTable
                                isNotEditor={isNotEditor}
                                isVendor={isVendor}
                                keys={tableKeys}
                                data={items}
                                actionTaken={action => {
                                    console.log(
                                        'inside',
                                        this.handleActionTaken
                                    )
                                    this.handleActionTaken(action)
                                }}
                                handleSort={(order, orderBy) =>
                                    this.handleSort(order, orderBy)
                                }
                            />
                            <PaginationBar
                                innerRef={this.paginationBottom}
                                labels={labels}
                                handlePagination={page =>
                                    this.handlePaginationClick(page, 'bottom')
                                }
                                isFooter={true}
                                rowsPerPage={rowsPerPage}
                                total={total}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    componentDidMount() {
        this.getData()
    }
}

export default SyncomsTable
