import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconHistory from '../../assets/icon-history.png'
import CheckCircleOutlineIcon from '../../assets/icon-check-circle.png';
import CheckIcon from '../../assets/icon-check.png'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Select, OutlinedInput, Divider } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import AppModal from "../../components/Modals/AppModal"

import EditToolbar from '../../components/Fields/EditToolbar';
import TabForm from '../../components/Forms/TabFormModification';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CalendarIcon from '../../assets/icon-calendar.png';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

// API
import API from "../../utils/API";

function TabContainer(props) {
  return (
      props.children
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  base: {},
  root: {
    // padding: theme.spacing.unit * 2
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  labelForDatePicker: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Calibri',
    fontSize: 18,
    fontWeight: 40,
    color: theme.palette.primary.main
  },
  labelInRequestTab: {
    fontSize: 18,
    fontWeight: 40,
    color: theme.palette.primary.main
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,
    paddingBottom: theme.spacing.unit*1,
    paddingLeft: theme.spacing.unit*2,
    paddingRight: theme.spacing.unit*2,
    marginTop: theme.spacing.unit*0.5,
    borderRadius: 24,
    fontSize: 18,
    width: 150,
    justifyContent: 'space-between',
    fontSize: 18,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff'
    }
  },
  pickerContainer: {
  },
  previousButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent'
    }
  },
  buttonIcon: {
    width: 19,
    height: 17
  },
  checkIcon: {
    width: 18,
    height: 'auto'
  },
  paperContainer: {
    position: 'relative'
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  formLabelForRequest: {
    color: theme.palette.primary.light,
    fontWeight: 'bold',
  },
  formRow: {
    marginBottom: theme.spacing.unit*3,
    fontSize: 17,
    color: theme.palette.primary.main
  },
  summarySelect: {
    width: '100%',
    height: 40,
  },
});

let baseLink = "/plan-modifications/";

class PlanModificationItem extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    let item = null;
    this.state = {
      currentTab: 0,
      value: 'true',
      item: item,
      users: [],
      vendors: [],
      underwriters: [],
      form_fields: [],
      fieldObj: {},
      options: {
        'tabs': []
      },
      underwriterCannotEdit: false,
      modalOpen: false,
      modalMessage: ''
    };

    if (!item) {
      setTimeout(() => this.getData());
    }
  }

  getUrl = () => {
    let url = baseLink;
    if (window.location.href.indexOf("add") >= 0) {
      url += "add/";
    }
    if (this.props.match.params.id) {
      url += this.props.match.params.id;
    }
    return url;
  }

  async getData() {
    // Load async data.
    let url = this.getUrl();

    let apiData = await API.get(url)
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
    // Parse the results
    let item = apiData.data.item,
        tabs = apiData.data.tabs,
        form_fields = apiData.data.form_fields,
        fieldObj = apiData.data.field_obj,
        users = apiData.data.users,
        vendors = apiData.data.vendors,
        underwriters = apiData.data.underwriters,
        options = this.state.options;

    options.tabs = tabs;

    const {underwriterCannotEdit} = this.setEditAccess(item);

    // Set the clients
    this.setState({
      ...this.state, ...{
        item,
        users,
        vendors,
        underwriters,
        options,
        form_fields,
        fieldObj,
        underwriterCannotEdit,
      }
    });
  }

  // To enable disabling form fields if user is an underwriter, only if plan status is not request_approval
  setEditAccess = (item) => {
    let underwriterCannotEdit = false;

    // if (role === 'Underwriter' && item.plan_status !== 'request_approval') {
    //   underwriterCannotEdit = true;
    // }

    return {underwriterCannotEdit};
  }

  handleChange = event => {
      this.setState({ [event.target.name]: event.target.value });
  };

  updateCurrentTab = (currentTab) => {
    this.setState({ currentTab })
  }

  handleNext = () => {
    this.setState({
      currentTab: this.state.currentTab + 1
    })
  }

  handlePrevious = () => {
    this.setState({
      currentTab: this.state.currentTab - 1
    })
  }

  handleDateChange = (date, name )=> {
    this.setState({ [name]: date });
  };

  getFormFields = (tab) => {
    let { form_fields } = this.state,
        fields = [];
    for (let formField of form_fields) {
      if (formField[0] == tab) {
        fields = formField;
      }
    }
    return fields;
  }

  handleDelete = (e) => {
    // Load async data.
    let url = this.getUrl();
    API.delete(url)
      .then((res) => {
        this.props.history.push('/users/');
      })
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
  }

  handleSave = (e) => {
    let item = this.state.item,
        url = this.getUrl(true);
    if (!this.isValidItem(item)) {
      this.setState(state => ({
        modalOpen: true,
        modalMessage: "You must complete the form before continuing"
      }))
      return;
    }
    let dict = {
      'plan_modification': item
    }
    API.post(url, dict)
      .then((res) => {
        if (res.data.success) {
        this.props.history.push(baseLink);
          } else {
            this.setState(state => ({
              modalOpen: true,
              modalMessage: "Cannot save with the current details"
            }))
        }

      })
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: "Cannot save with the current details"
        }))
      });
  }

  handleModalClose = () => {
    this.setState(state => ({
      modalOpen: false,
    }))
  }

  isValidItem = (item) => {
    return true;
  }

  render() {
    const { classes } = this.props;
    const { options, currentTab, item, fieldObj, users, vendors, underwriters, underwriterCannotEdit } = this.state;
    let id = this.props.match.params.id;
    return (
      <div className={classes.base}>
        <AppModal open={this.state.modalOpen} textMessage={this.state.modalMessage} handleClose={this.handleModalClose}/>
        <Grid container component="main" alignItems={'center'} className={classes.root}>
          {item &&
            <Grid item xs={9}>
              <Typography component="h2" variant="h1" className="pageTitle">
                {item.name}
              </Typography>
            </Grid>
          }
          <Grid item xs={3} align="right">
            <IconButton component={Link} to={`/plan-modifications/${id}/history`} className={classes.blueButton}>
              History
              <img src={IconHistory} className={classes.buttonIcon}/>
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <form className={classes.form} noValidate>
              <Paper className={classes.paperContainer}>
                <Grid container className={classes.formcontainer}>
                  <EditToolbar options={options} currentTab={currentTab} updateCurrentTab={this.updateCurrentTab} />
                </Grid>
                <Divider className="searchLine searchLine__toolbar"/>
                <Grid container className={classes.formcontainer}>
                  <Grid item xs={12} sm={6}>
                    <Typography component="h3" className={classes.paperTitle}>{options.tabs[currentTab]}</Typography>
                  </Grid>
                </Grid>
                {options && options.tabs &&
                  <React.Fragment>
                  {options.tabs.map((tab, index) => {
                    let formFields = null;
                    if (currentTab == index) {
                      formFields = this.getFormFields(tab);
                    }
                      return (
                        <TabContainer>
                        {formFields &&
                          <TabForm
                            underwriterCannotEdit={underwriterCannotEdit}
                            item={item}
                            formFields={formFields}
                            fieldObj={fieldObj}
                            users={users}
                            vendors={vendors}
                            underwriters={underwriters}
                          />
                        }
                        </TabContainer>
                      )
                    }
                    )}
                  </React.Fragment>
                }
                <Divider className="searchLine__bottom"/>
                <Grid item xs={6} style={{marginTop: 60}} align="left">
                  {(currentTab < (options.tabs.length - 1)) &&
                    <IconButton onClick = {this.handleNext} style={{ marginRight: 10 }} className={classes.blueButton}>
                      Next
                      <KeyboardArrowRightIcon className={classes.dropdownIcon}/>
                    </IconButton>
                  }
                  {currentTab > 0 &&
                    <IconButton
                    onClick = {this.handlePrevious}
                    className={`${classes.blueButton} ${classes.previousButton}`}
                    >
                      Previous
                    </IconButton>
                  }
                  {(currentTab == (options.tabs.length - 1)) &&
                    <React.Fragment>
                      {
                        !underwriterCannotEdit &&
                        <IconButton onClick={(e) => this.handleSave(e)} style={{marginRight: 10, width: 'auto'}}
                                    className={classes.blueButton}>
                          Submit Plan
                          <KeyboardArrowRightIcon className={classes.dropdownIcon}/>
                        </IconButton>
                      }
                      <IconButton
                          href="/plan-modifications"
                          className={`${classes.blueButton} ${classes.previousButton}`}>
                           Reject Plan
                      </IconButton>
                    </React.Fragment>
                  }
                </Grid>
              </Paper>
            </form>
          </Grid>
          </Grid>
      </div>
    );
  }
}

PlanModificationItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PlanModificationItem);
