import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AutoComplete from '../Fields/AutoComplete';

const styles = theme => ({
  root: {},
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  row:{
    marginBottom:20,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  label:  {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72',
  }
});

class EventDetailsForm extends React.Component {
  // const classes = useStyles();
  render() {
    const { classes } = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12}>
          <form className={classes.form} noValidate>
            <Typography variant="h2" gutterBottom>Event Details</Typography>
            <Grid container >
              <Grid item xs={12}>
                <Grid container className={classes.row}>
                  <Grid item xs={12} sm={3} className={classes.label}>Name of Event</Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      required
                      fullWidth
                      id="name_of_event"
                      name="name"
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item xs={12} sm={3} className={classes.label}>Campaign Location</Grid>
                  <Grid item xs={12} sm={5}>
                    <AutoComplete list="location"/>
                  </Grid>
                </Grid> 
                <Grid container className={classes.row}>
                  <Grid item xs={12} sm={3} className={classes.label}>Type of event</Grid>
                  <Grid item xs={12} sm={5}>
                    <AutoComplete list="event"/>
                  </Grid>
                </Grid>  
              </Grid>
            </Grid>
            </form>
        </Grid>
      </Grid>
    );
  }
}

EventDetailsForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventDetailsForm);