import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';

import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import ProfileUpload from '../../utils/ProfileUpload';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  base: {},
  root: {
    padding: theme.spacing.unit *2
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  row: {
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit* 3
  },
  pagetitle: {
    color:theme.palette.primary.main,
    fontSize: 36,
    fontWeight: 500,
    paddingLeft: theme.spacing.unit * 2,
  },
  formRow: {
    marginBottom: 20,
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: '12px',
    color: '#6d6f72'
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  profileUpload: {
    padding:30,
    border: '2px dashed #e2eef6',
    borderRadius: 3,
    fontFamily: "Calibri, sans-serif",
    textAlign:'center',
    fontSize:  14,
    color: '#1b4059',
    marginBottom: 30,
  },
  photoContainer: {
    height:80,
    width:80,
    backgroundColor:'#e2eef6',
    padding:10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
  },
  avatar: {
    width:60,
    height:60
  },
  buttonRow: {
    borderTop: '1px solid #e2e2e2',
    marginTop: 10,
    paddingTop: 30
  }
});

class Profile extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {
      value: 'true',
      checkedCreate: '',
      checkedEdit: '',
      user: {}
    };
  }

  handleBin = type => {
    
  }

  handleChange = (event, variable) => {
      this.setState({ value: event.target.value });
    };

    handleCheckboxChange = name => event => {
        this.setState({ [name]: event.target.checked });
      };



  render() {
    const { classes } = this.props;
    const { user } = this.state;
    return (
      <div className={classes.root}>
        <Grid container component="main" className={classes.row}>
          <Grid item>
            <Typography variant="h2" gutterBottom className={classes.pagetitle}>Profile</Typography>
          </Grid>
        </Grid>
        <form className={classes.form} noValidate>
          <Paper className={classes.root}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Grid container className={classes.formRow}>
                  <Grid item xs={12} sm={4} className={classes.label}>Full Name</Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      required
                      fullWidth
                      id="name_of_sensor"
                      name="name"
                      className={classes.textfield}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.formRow}>
                  <Grid item xs={12} sm={4} className={classes.label}>Username</Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                        required
                        fullWidth
                        id="short_name"
                        name="short_name"
                        className={classes.textfield}
                      />
                  </Grid>
                </Grid>
                <Grid container className={classes.formRow}>
                  <Grid item xs={12} sm={4} className={classes.label}>Password</Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                        required
                        fullWidth
                        type="password"
                        id="short_name"
                        name="short_name"
                        className={classes.textfield}
                      />
                  </Grid>
                </Grid>
                <Grid container className={classes.formRow}>
                  <Grid item xs={12} sm={4} className={classes.label}>Email Address</Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                        required
                        fullWidth
                        type="email"
                        id="short_name"
                        name="short_name"
                        className={classes.textfield}
                      />
                  </Grid>
                </Grid>
                <Grid container className={classes.formRow}>
                  <Grid item xs={12} sm={4} className={classes.label}>Phone Number</Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                        required
                        fullWidth
                        type="tel"
                        id="short_name"
                        name="short_name"
                        className={classes.textfield}
                      />
                  </Grid>
                </Grid>
                <Grid container className={classes.formRow}>
                  <Grid item xs={12} sm={4} className={classes.label}>Profile Photo</Grid>
                  <Grid item xs={12} sm={8}>
                    <div className={classes.profileUpload}>
                      <ProfileUpload /> 
                    </div>
  
                    <div className={classes.photoContainer}>
                      <Avatar alt="Profile Picture" src={user.image ? user.image : 'https://via.placeholder.com/40x40'} className={classes.avatar} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>  
            <Grid container className={classes.buttonRow}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                  className={classes.button}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Profile);