import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { withRouter, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AppModal from "../../components/Modals/AppModal"

import EditToolbar from '../../components/Fields/EditToolbar';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';
import IconHistory from '../../assets/icon-history.png'

// COMPONENTS
import { Divider } from '@material-ui/core';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// API
import API from "../../utils/API";

const styles = theme => ({
  base: {},
  root: {
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paperContainer: {
    position: 'relative'
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dropdownIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1,
    display: 'none'
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  pageTitle: {
    color:theme.palette.primary.main,
    fontWeight: 800,
    fontSize: 36,
    paddingTop: theme.spacing.unit*6,
    paddingBottom: theme.spacing.unit*6
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,
    paddingBottom: theme.spacing.unit*1,
    paddingLeft: theme.spacing.unit*2,
    paddingRight: theme.spacing.unit*2,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit*0.5,
    borderRadius: 24,
    fontSize: 18,
    width: 150,
    justifyContent: 'space-between',
    fontSize: 18,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#27cfda'
    }
  },
  buttonIcon: {
    width: 19,
    height: 17
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  formRow: {
    marginBottom: theme.spacing.unit*3
  }
});
let baseLink = "/introductions/";

class IntroductionItem extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    let item = {};
    if ((this.props.display == 'modal' && this.props.editType == 'edit') || (this.props.display != 'modal' && this.props.match.params.id)) {
      item = null;
    }
    let actions = ['save'];
    if (this.props.display != 'modal') {
      actions.push('delete');
    }
    this.state = {
      value: 'true',
      item: item,
      options: {
        'actions': actions,
      },
      modalOpen: false,
      modalMessage: ''
    };
    if (!item) {
      setTimeout(() => this.getData());
    }
  }

  getUrl = () => {
    let url = baseLink;
    if (this.props.display == 'modal' && this.props.editType == 'edit' && this.props.value) {
      url += this.props.value
    } else if (this.props.display != 'modal' && this.props.match.params.id) {
      url += this.props.match.params.id;
    }
    return url;
  }

  async getData() {
    // Load async data.
    let url = this.getUrl();

    let apiData = await API.get(url)
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
    // Parse the results
    let item = apiData.data.item;

    // Set the clients
    this.setState({
      ...this.state, ...{
        item: item
      }
    });
  }

  handleAction = (action) => {
    if (action == 'save') {
      this.handleSave();
      return;
    }
    if (action == 'delete') {
      this.handleDelete();
      return;
    }
  }

  handleDelete = (e) => {
    // Load async data.
    let url = this.getUrl();
    API.delete(url)
      .then((res) => {
        this.props.history.push('/users/');
      })
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
  }

  handleSave = (e) => {
    let item = this.state.item,
        url = this.getUrl(true);
    if (!this.isValidItem(item)) {
      this.setState(state => ({
        modalOpen: true,
        modalMessage: "You must complete the form before continuing"
      }))
      return;
    }
    let dict = {
      'introduction': item
    }
    API.post(url, dict)
      .then((res) => {
        if (res.data.success) {
          if (this.props.display == 'modal') {
            this.props.handleUpdate(res.data.id);
          } else {
            this.props.history.push(baseLink);
          }
        } else {
            this.setState(state => ({
              modalOpen: true,
              modalMessage: "Cannot save with the current details"
            }))
        }

      })
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: "Cannot save with the current details"
        }))
      });
  }

  handleUpdate = (key, value) => {
    let item = this.state.item;
    item[key] = value;
    this.setState({ item });
  }

  isValidItem = (item) => {
    return true;
  }

  handleChange = event => {
      this.setState({ value: event.target.value });
  };

  handleModalClose = () => {
    this.setState(state => ({
      modalOpen: false,
    }))
  }

  render() {
    const { classes, display, value } = this.props;
    const { item, options } = this.state;
    let id = (display == 'modal') ? value : this.props.match.params.id;
    return (
      <div className={classes.base}>
        <AppModal open={this.state.modalOpen} textMessage={this.state.modalMessage} handleClose={this.handleModalClose}/>
        <Grid container component="main" alignItems={'center'} className={classes.root}>
          {!display &&
            <React.Fragment>
            <Grid item xs={9}>
            <Typography component="h2" variant="h1" className={classes.pageTitle}>
              Introduction
              <KeyboardArrowUpIcon className={classes.dropdownIcon}/>
            </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <IconButton component={Link} to={`/introductions/${id}/history`} className={classes.blueButton}>
              History
              <img src={IconHistory} className={classes.buttonIcon}/>
              </IconButton>
            </Grid>
            </React.Fragment>
          }
          <Grid item xs={12}>
            {item &&
              <form className={classes.form} noValidate>
                <Paper className={classes.paperContainer}>
                  <Grid container className={classes.formcontainer}>
                    <EditToolbar options={options} handleAction={(action)=> this.handleAction(action)} />
                    <Divider className='searchLine searchLine__toolbar'/>
                  </Grid>
                  <Grid container style={{ marginTop: 15 }} className={classes.formcontainer}>
                    <Grid item xs={12} sm={6}>
                      <Typography component="h3" className={classes.paperTitle}>
                         Change Introduction
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.formRow}>
                    <Grid item xs={12} sm={4}>
                      <Grid item xs={12} sm={12} className="formLabel">Name</Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          fullWidth
                          id="name_of_sensor"
                          name="name"
                          value={item.name}
                          onChange={e => this.handleUpdate('name', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.formRow}>
                      <Grid item xs={12} sm={8} className="formLabel">Content</Grid>
                      <Grid item xs={12} sm={8}>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={item.content}
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                console.log( { event, editor, data } );
                                this.handleUpdate('content', data);
                            } }
                            config={{
                              removePlugins: ['MediaEmbed', 'ImageUpload'],
                              toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', 'undo', '|', 'redo' ]
                            }}
                        />
                      </Grid>
                  </Grid>
                </Paper>
              </form>
            }
          </Grid>
        </Grid>
      </div>
    );
  }
}

IntroductionItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IntroductionItem);
