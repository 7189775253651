import React from 'react';
import { Route, withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, Paper, Toolbar, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import { lighten } from '@material-ui/core/styles/colorManipulator';

// Icons
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Divider from '@material-ui/core/Divider';

//Components
import PaginationBar from '../../components/Fields/PaginationBar';
import AdminTable from '../../components/Fields/AdminTable';
import SearchBar from '../../components/Fields/SearchBar';

import SyncomsTable from '../../components/SyncomsTable';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';

const styles = theme => ({
  root: {},
  table: {
    flexGrow: 1,
    width:'100%'
  },
  iconButton: {
    padding: 10,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    fontSize: 12,
    borderRadius: 5,
    marginLeft: theme.spacing.unit * 1,
    fontWeight: 500,
    fontFamily: "Calibri, sans-serif",
  }, 
  edit: {
    marginRight: 15,
    height: 16,
    width: 'auto', 
    color: '#445f6d',
  },
  dropdownIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1,
    display: 'none'
  },
  primaryTable: {
    flexGrow: 1,
    padding: '15px 30px',
    position: 'relative',
    paddingBottom: 40
  }, 
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,   
    paddingBottom: theme.spacing.unit*1,   
    paddingLeft: theme.spacing.unit*2, 
    paddingRight: theme.spacing.unit*2,
    borderRadius: 15,
    fontSize: 18,
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#27cfda'
    }
  },
  buttonIcon: {
    marginLeft: 15,
  },
  searchLine: {
    position: 'static'
  }
});

let counter = 0;

let baseLink = "/users/",
    tableKeys = [
      {'label':'Username', 'id': 'username', 'numeric': false, 'disablePadding': true, },
      {'label':'Email', 'id': 'email', 'numeric': false, 'disablePadding': true, },      
      {'label':'First Name', 'id': 'first_name', 'numeric': false, 'disablePadding': true, },      
      {'label':'Last Name', 'id': 'last_name', 'numeric': false, 'disablePadding': true, },      
      {'label':'Staff Status', 'id': 'is_staff', 'numeric': true, 'disablePadding': true, },
    ],
    labels = {
      'singular': "User",
      "plural": "Users",
      "placeholder": "Search Users"
    };


class UserList extends SyncomsTable {
  constructor(props) {
    super(props);
    this.state.baseLink = baseLink;
    this.state.labels = labels;
    this.state.tableKeys = tableKeys;
  }

  updateItem(item) {
    item.username = {'label': item.username, 'type': 'link', id: item.id, path: baseLink};
    item.is_staff = {'label': item.is_staff, 'type': 'boolean'};
    return item;
  }
}

UserList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserList);
